import {
    Card, CardContent, Container, Button, Stack, Dialog, DialogTitle,
    DialogContent, DialogContentText, DialogActions
} from "@mui/material"
import React, { useContext, useState } from "react"
import GlobalStyles from '../css/GlobalStyles.module.css'
import AddIcon from '@mui/icons-material/Add'
import SearchField from './common/SearchField.jsx'
import { SnackbarContext } from "../context/SnackbarContext.js"
import {
    getOrgUnitPathsAPI,
    createOrgUnitPathAPI,
    updateOrgUnitPathAPI,
    deleteOrgUnitPathAPI,
    createOrgOnGoogleAPI,
    updateOrgOnGoogleAPI,
    deleteOrgUnitPathOnGoogleAPI
} from '../apis/orgUnitPathAPI.js';
import OrgUnitTable from "./OrgUnitTable.jsx"
import OrgUnitPathForm from "./OrgUnitPathForm.jsx"

const OrgUnitPaths = () => {
    const [orgUnitPath, setOrgUnitPath] = useState([])
    const [search, setSearch] = useState('')
    const [newOrgPathForms, setNewOrgPathForms] = useState(false)
    const [editingRowId, setEditingRowId] = useState('')
    const [orgData, setOrgData] = useState({
        org_unit_path: '',
        parent_org_unit_path: '',
        description: '',
        etro_team: ''
    })

    return (
        <Container>
            <Card className={GlobalStyles.card}>
                <CardContent>
                    <Stack mb={1} justifyContent='space-between' direction="row" alignItems="center" >
                        <SearchField setSearch={setSearch} />
                        <Button
                            onClick={() => {
                                setEditingRowId(null)
                                setNewOrgPathForms(!newOrgPathForms)
                            }}
                            variant="text"
                            startIcon={<AddIcon />}
                        >Add New</Button>
                    </Stack>
                    {newOrgPathForms && (
                        <OrgUnitPathForm
                            setOrgData={setOrgData}
                            orgData={orgData}
                            setNewOrgPathForms={setNewOrgPathForms}
                            newOrgPathForms={newOrgPathForms}
                            setEditingRowId={setEditingRowId}
                        />
                    )}
                    <OrgUnitTable
                        data={orgUnitPath}
                        setEditingRowId={setEditingRowId}
                        setOrgData={setOrgData}
                        search={search}
                        newOrgPathForms={newOrgPathForms}
                        setNewOrgPathForms={setNewOrgPathForms}
                        orgData={orgData}

                    />
                </CardContent>
            </Card>
            
        </Container>
    )

}


export default OrgUnitPaths
