import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react'
import {
    Stack, Paper, Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Button, IconButton, Tooltip, Box, Snackbar, Alert,
    Typography
} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit'
import api from '../apis/api';
import BackdropLoader from './common/BackdropLoader.jsx'
import AdditionalInformation from './AdditionalInformation.jsx'
import FirstGoalPDF from './FirstGoal.jsx';
import { DataGrid } from '@mui/x-data-grid';
import { GoalsContext } from '../context/GoalsContext.js';
import { CheckInContext } from '../context/CheckInContexts.js';
import { deleteGoalAPI } from '../apis/goalsAPI.js'

const Goals = (props) => {
    const { playerId, peopleBuilder } = props
    const { getGoals, goals } = useContext(GoalsContext)
    const { getCheckIns, checkIns } = useContext(CheckInContext)
    const [userGoals, setUserGoals] = useState([{
        goal_name: '',
        goal_type: '',
        target_date: '',
        status: '',
        last_check_in: '',
        check_in_status: '',
        id: 0
    }])
    const [openFirstGoalPDF, setOpenFirstGoalPDF] = useState(false)
    const navigate = useNavigate();
    const [openDelete, setOpenDelete] = useState(false)
    const [goalSelectedToDelete, setGoalSelectedToDelete] = useState({ goal_name: '', id: '' })
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [userLogged] = useState(JSON.parse(localStorage.getItem('user')))
    const [isCoach] = useState(userLogged.id === peopleBuilder.coach_id)
    const [isPlayer] = useState(userLogged.id === peopleBuilder.player_id)
    const [isAdmin] = useState(userLogged.is_admin)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [severytypeSnackbar, setSeverytypeSnackbar] = useState('')
    const [messageSnackbar, setMessageSnackbar] = useState('')
    const [loadingGoals, setLoadingGoals] = useState(true)

    const columns = useMemo(() => [
        {
            field: 'goal_name',
            headerName: 'Goal Name',
            headerClassName: 'goal-header',
            width: 300,
        },
        {
            field: 'goal_type',
            headerName: 'Goal Type',
            headerClassName: 'goal-header',
            width: 350,
        },
        {
            field: 'target_date',
            headerName: 'Target Date',
            headerClassName: 'goal-header',
            width: 300,
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'goal-header',
            width: 150,
        },
        {
            field: 'last_check_in',
            headerName: 'Last Check-In',
            headerClassName: 'goal-header',
            width: 300,
            flex: 1
        },
        {
            field: 'check_in_status',
            headerName: 'Check-In Status',
            headerClassName: 'goal-header',
            width: 300,
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Actions',
            headerClassName: 'goal-header',
            width: 300,
            flex: 1,
            renderCell: (params) => {
                return (
                    // <Fade in={focusedRow === params.id} timeout={200}>
                    <Stack direction='row' spacing={1}>
                        <IconButton>
                            <Tooltip title='Edit Goal'>
                                <EditIcon
                                    onClick={(event) => handleRowClick(params.id)}
                                />
                            </Tooltip>
                        </IconButton>
                        <IconButton>
                            <Tooltip title='Delete Goal'>
                                <DeleteIcon
                                    className="delete-icon"
                                    onClick={() => {

                                        if (params.row?.status === 'Draft Goal' && isCoach) {
                                            openAlert('This goal is not finished yet', 'error')
                                            return
                                        } else if (!isPlayer && !isCoach) {
                                            openAlert('You are not own from this goal', 'error')
                                            return
                                        }

                                        setOpenDelete(true)
                                        setGoalSelectedToDelete({ goal_name: params.row.goal_name, id: params.row.id })
                                    }} />
                            </Tooltip>
                        </IconButton>
                    </Stack>
                    // </Fade>
                )
            },
            // renderActionsCell: (params) => {
            //     console.log('params', params)
            // }
        },
    ], [])

    const handleClose = () => {
        setOpenSnackbar(false)
        setOpenFirstGoalPDF(false)
    }

    const openAlert = (message, type) => {
        setOpenSnackbar(true)
        setSeverytypeSnackbar(type)
        setMessageSnackbar(message)
    }

    const handleRowClick = useCallback(async (goalId) => {
        const goalSelected = goals.find(goal => goal.id === goalId)

        if (goalSelected?.goal_status === 'Draft Goal' && isCoach) {
            openAlert('This goal is not finished yet', 'error')
            return
        } else if (!isCoach && !isPlayer && !isAdmin) {
            openAlert('You are not own from this goal', 'error')
            return
        }

        navigate(`/goal-form/people-builder/${goalSelected?.people_builder_id}/goal/${goalId}`)
    }, [goals])

    const openGoalForm = () => {
        const filterGoalsByUser = goals.filter(goal => goal?.people_builder?.player_id === playerId && !goal.goal_status.includes('archived'))
        if (!filterGoalsByUser.length) {
            setOpenFirstGoalPDF(true)
        } else {
            navigate(`/goal-form/people-builder/${peopleBuilder?.id}`)
        }

    }

    const findMostRecentCheckin = (checkins) => {
        if (!checkins?.length) return null;

        return checkins.reduce((mostRecent, current) => {
            return new Date(current.check_in_date) > new Date(mostRecent.check_in_date) ? current : mostRecent;
        });
    };

    const getUserGoals = useCallback(async () => {
        // await getGoals()
        const userGoalsFiltered = goals.filter(goal => goal?.people_builder?.player_id === playerId && !goal.goal_status.includes('archived') && peopleBuilder.year === goal.people_builder.year)
        const userCheckins = checkIns?.filter(checkin => checkin.player_id === playerId)
        const mostRecentCheckin = findMostRecentCheckin(userCheckins);

        const goalsFixed = userGoalsFiltered.map((goal, index) => {

            return {
                goal_name: goal.goal_name,
                goal_type: goal.goal_term,
                target_date: goal.target_date ? new Date(goal.target_date)?.toISOString().split('T')[0] : '',
                status: goal.goal_status,
                last_check_in: mostRecentCheckin?.check_in_date?.split('T')[0],
                check_in_status: mostRecentCheckin?.check_in_goals[index]?.check_in_goal_status ?? '',
                id: goal.id
            }
        })

        setUserGoals(goalsFixed)
        setLoadingGoals(false)
    }, [goals, checkIns])

    const deleteGoal = async () => {
        setOpenBackdrop(true)
        setOpenDelete(false)
        const deletedGoal = await deleteGoalAPI(goalSelectedToDelete.id)
        setOpenBackdrop(false)
        openAlert(deletedGoal?.message)
        await getGoals()
        await getCheckIns()
    }

    const getRowClassName = (params) => {

        if (params.row.status === 'Draft Goal' && isCoach) {
            return 'disabled-row';
        } else if (!isCoach && !isPlayer && !isAdmin) {
            return 'disabled-row';
        }
        return '';
    };

    const getDataGridStyles = () => ({
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
            backgroundColor: 'none'
        },
        '& .MuiDataGrid-cell:hover': {
            backgroundColor: 'none',
        },
        '& .goal-header': {
            backgroundColor: '#ccc',
        },
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    });

    useEffect(() => {
        getUserGoals()
    }, [goals, checkIns])

    useEffect(() => {
        getGoals()
        getCheckIns()
    }, [])

    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={severytypeSnackbar}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {messageSnackbar}
                </Alert>
            </Snackbar>
            <Paper>
                {openBackdrop && (
                    <BackdropLoader openBackdrop={open} />
                )}
                <AdditionalInformation peopleBuilder={peopleBuilder} />
                {/* {userGoals.length ? ( */}
                <DataGrid
                    rows={userGoals}
                    columns={columns}
                    disableColumnResize={true}
                    rowSelection={false}
                    getRowClassName={getRowClassName}
                    loading={loadingGoals}
                    localeText={{ noRowsLabel: "No goals" }}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton'
                        }
                    }}
                    onRowClick={(params, event) => {

                        if (!event.target.closest('.delete-icon')) {
                            handleRowClick(params.id)
                        }
                    }}
                    paginationModel={{ page: 0, pageSize: 6 }}
                    pageSizeOptions={[6]}
                    sx={getDataGridStyles}
                />
                {/* ) : (
                //     <DataGrid
                //         rows={[{ goal_name: 'No Goals', id: 0 }]}
                //         columns={[
                //             {
                //                 field: 'goal_name',
                //                 headerName: 'Goal Name',
                //                 headerClassName: 'goal-header',
                //                 width: 300,
                //                 colSpan: 7,
                //                 align: 'center'
                //             },
                //             {
                //                 field: 'goal_type',
                //                 headerName: 'Goal Type',
                //                 headerClassName: 'goal-header',
                //                 width: 400,
                //             },
                //             {
                //                 field: 'target_date',
                //                 headerName: 'Target Date',
                //                 headerClassName: 'goal-header',
                //                 width: 300,
                //                 flex: 1
                //             },
                //             {
                //                 field: 'status',
                //                 headerName: 'Status',
                //                 headerClassName: 'goal-header',
                //                 width: 100,
                //             },
                //             {
                //                 field: 'last_check_in',
                //                 headerName: 'Last Check-In',
                //                 headerClassName: 'goal-header',
                //                 width: 300,
                //                 flex: 1
                //             },
                //             {
                //                 field: 'check_in_status',
                //                 headerName: 'Check-In Status',
                //                 headerClassName: 'goal-header',
                //                 width: 300,
                //                 flex: 1
                //             },
                //             {
                //                 field: 'actions',
                //                 headerName: 'Actions',
                //                 headerClassName: 'goal-header',
                //                 width: 300,
                //                 flex: 1
                //             }
                //         ]}
                //         localeText={{ noRowsLabel: "No goals" }}
                //         loading={loadingGoals}
                //         slotProps={{
                //             loadingOverlay: {
                //                 variant: 'skeleton',
                //                 noRowsVariant: 'skeleton'
                //             },

                //         }}
                //         disableColumnResize={true}
                //         paginationModel={{ page: 0, pageSize: 1 }}
                //         disableRowSelectionOnClick={true}
                //         sx={getDataGridStyles}
                //     />
                // )}*/}


                {/* <Table size="small" aria-label="purchases">
                    <TableHead >
                        <TableRow sx={{ backgroundColor: '#ccc' }} >
                            <TableCell>Goal Name</TableCell>
                            <TableCell>Goal Type</TableCell>
                            <TableCell>Target Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Last Check-In</TableCell>
                            <TableCell>Check-In Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userGoals.length > 0 ? (

                            userGoals.map((goal) => (
                                <TableRow
                                    sx={{ cursor: 'pointer' }}
                                    hover
                                    key={goal.id}
                                    onMouseEnter={() => {
                                        setFocusedRow(goal.id)
                                    }}
                                    onMouseLeave={() => setFocusedRow(null)}
                                    onClick={(event) => {
                                        if (!event.target.closest('.delete-icon')) {
                                            handleRowClick(goal.id, goal.people_builder_id)
                                        }
                                    }}
                                >
                                    <TableCell>{goal.goal_name}</TableCell>
                                    <TableCell>{goal.goal_term}</TableCell>
                                    <TableCell>{goal.target_date?.split('T')[0]}</TableCell>
                                    <TableCell>{goal.goal_status}</TableCell>
                                    <TableCell>{goal.updatedAt.split('T')[0]}</TableCell>
                                    <TableCell>{goal.checkin_status}</TableCell>
                                    <TableCell>
                                        <>
                                            <Fade in={focusedRow === goal.id} timeout={200}>
                                                <Stack direction='row' spacing={1}>
                                                    <IconButton>
                                                        <Tooltip title='Edit Goal'>
                                                            <EditIcon
                                                                onClick={(event) => handleRowClick(goal.id, goal.people_builder_id)}
                                                            />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <IconButton>
                                                        <Tooltip title='Delete Goal'>
                                                            <DeleteIcon
                                                                className="delete-icon"
                                                                onClick={() => {
                                                                    setOpenDelete(true)
                                                                    setGoalSelectedToDelete({ goal_name: goal.goal_name, id: goal.id })
                                                                }} />
                                                        </Tooltip>
                                                    </IconButton>
                                                </Stack>
                                            </Fade>
                                        </>
                                    </TableCell>
                                </TableRow>
                            ))) : (
                            <TableRow>
                                <TableCell colSpan={8} >
                                    <Typography textAlign='center' variant='h5'>No Goals</Typography>
                                </TableCell>

                            </TableRow>
                        )
                        }
                    </TableBody>
                </Table> */}

                <Dialog
                    open={openFirstGoalPDF}
                    onClose={handleClose}
                    onBlur={handleClose}
                    fullWidth={true}
                    maxWidth='xl'
                    // onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <FirstGoalPDF peopleBuilder={peopleBuilder} />
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={openDelete}
                    // onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are about to delete {goalSelectedToDelete.goal_name}.
                            Are you sure you want to do this?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
                        <Button onClick={deleteGoal}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </Paper>
            {
                isCoach || isPlayer ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Tooltip title='Add New Goal'>
                            <IconButton onClick={openGoalForm}>
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ) : (
                    <Tooltip title={`You do not have access to create new Goal`}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <IconButton disabled onClick={openGoalForm}>
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Box>
                    </Tooltip>
                )
            }
        </React.Fragment >
    )
}

export default Goals