import { DataGrid } from '@mui/x-data-grid'
import React, { useState, useEffect, useCallback } from 'react'
import api from '../apis/api.js'
import {
    Select, MenuItem, Button, Dialog, DialogContent, DialogTitle, DialogActions,
    IconButton, Tooltip, TextField
} from '@mui/material'
import CommentIcon from '@mui/icons-material/Comment';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import { useParams } from 'react-router-dom';
import { getGoalsAPI } from '../apis/goalsAPI.js'
import CommentsTimeline from '../components/common/CommentsTimeline.jsx';

const CheckinGoals = ({ checkinGoalData, setCheckinGoalData, checkin }) => {
    const { playerId, checkinId } = useParams()
    const [goals, setGoals] = useState([])
    const [editingId, setEditingId] = useState(null)
    const [openDialogGoalComment, setOpenDialogGoalComment] = useState(false)
    const [goalComment, setGoalComment] = useState('')
    const [goalSelectedToComment, setGoalSelectedToComment] = useState([])
    const [comment, setComment] = useState('')
    const [comments, setComments] = useState([])

    const handleCloseDialog = () => {
        setOpenDialogGoalComment(false)
    }

    const handleOpenDialog = (goal) => {

        if (!goal.goal_comments) {
            setGoalSelectedToComment(goal)
            setOpenDialogGoalComment(true)
            setGoalComment('')
        } else {
            setGoalSelectedToComment(goal)
            setOpenDialogGoalComment(true)
            setGoalComment(goal.goal_comments)
        }
    }

    const handleClose = () => {
        setOpenDialogGoalComment(false)
    }

    const handleStatusChange = (id, value) => {
        const newGoalsData = []
        goals.map((goal) => {
            if (goal.id === id) {
                goal.check_in_goal_status = value
            }
            newGoalsData.push(goal)
        })
        setEditingId(null)
        setGoals(newGoalsData)
        setCheckinGoalData((prevStates) => prevStates.map((goal) => goal.id === id ? {
            ...goal,
            check_in_goal_status: value
        } : goal))
    }

    const handleCommentsChange = (id, value) => {

        const newGoalsData = []
        goals.map((goal) => {
            if (goal.id === id) {
                goal.goalComments = value
            }
            newGoalsData.push(goal)
        })
        setEditingId(null)
        setGoals(newGoalsData)
        setCheckinGoalData((prevStates) => prevStates.map((goal) => goal.id === id ? {
            ...goal,
            goal_comments: value
        } : goal))
    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            hide: true
        },
        {
            field: 'goalTitle',
            headerClassName: 'goal-header',
            headerName: 'Goal Title',
            width: 150,
            flex: 2
        },
        { field: 'targetDate', headerClassName: 'goal-header', headerName: 'Target Date', width: 150, flex: 1 },
        { field: 'lastCheckinDate', headerClassName: 'goal-header', headerName: 'Last Check-in Date', width: 150, flex: 1 },
        {
            field: 'check_in_goal_status',
            headerName: 'Check-in Status',
            headerClassName: 'goal-header',
            editable: (params) => !params.row.goal_status.includes('archived'),
            width: 150,
            renderCell: (params) => {
                const isDisabled = params.row.goal_status === 'archived'

                return (
                    <Select
                        value={params.value}
                        disabled={isDisabled}
                        onChange={(e) => handleStatusChange(params.id, e.target.value)}
                        onBlur={() => setEditingId(null)}
                        fullWidth
                        sx={{ height: '80%' }}
                        autoFocus
                    >
                        {params.value}
                        <MenuItem value="Complete">Complete</MenuItem>
                        <MenuItem value="Delayed">Delayed</MenuItem>
                        <MenuItem value="Not Started">Not Started</MenuItem>
                        <MenuItem value="On Track">On Track</MenuItem>

                    </Select >
                )
                // : (
                //     <div>
                //         {params.value}
                //     </div>
                // );
            }
        },
        {
            field: 'hasComments',
            headerName: 'Comments',
            headerClassName: 'goal-header',
            renderCell: (params) => {
                const isDisabled = params.row.goal_status === 'archived'

                return !isDisabled ? (
                    !params.row.goal_comments ? (
                        <div>
                            <Tooltip title='No Comments'>
                                <IconButton
                                    onClick={() => {
                                        handleOpenDialog(params.row)
                                        setGoalComment(params.row.goalComments)
                                    }}>
                                    <CommentsDisabledIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    ) : (
                        <div>
                            <Tooltip title='Has Comments'>
                                <IconButton
                                    onClick={() => {
                                        handleOpenDialog(params.row)
                                        setGoalComment(params.row.goalComments)
                                    }}>

                                    <MarkUnreadChatAltIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                ) : (
                    <div>
                        <IconButton disabled>
                            <CommentIcon />
                        </IconButton>
                    </div >
                )
            }
        },

    ]

    const fetchGoals = async () => {
        const goalData = []
        const data = await getGoalsAPI()
        let dataFiltered = data.filter((goal) => goal.people_builder.player_id === playerId && goal.goal_status !== 'archived')
        let checkinGoalsIds = checkin?.check_in_goals?.map((goal) => goal.goal_id)

        if (dataFiltered.length === 0) {
            dataFiltered = data.filter((goal) => checkinGoalsIds?.includes(goal.id))
        }

        dataFiltered.map((goal) => {
            goalData.push({
                id: goal.id,
                goal_id: goal.id,
                goalTitle: goal.goal_name,
                goalType: goal.goal_term,
                targetDate: goal.target_date?.split('T')[0],
                lastCheckinDate: goal.last_checkin_date,
                check_in_goal_status: goal.checkin_status,
                goal_comments: goal.goal_comments,
                goal_status: goal.goal_status,
            })
        })
        setGoals(goalData)
        setCheckinGoalData(goalData)
    }

    const getCheckinGoals = async () => {
        const goalData = []
        let comments = []
        checkin?.check_in_goals?.map((goal) => {
            goalData.push({
                id: goal.id,
                goal_id: goal.goal_id,
                goalTitle: goal.goal.goal_name,
                goalType: goal.goal.goal_term,
                targetDate: goal.goal.target_date?.split('T')[0],
                lastCheckinDate: goal.updated_at.split('T')[0],
                check_in_goal_status: goal.check_in_goal_status,
                goal_comments: goal.goal_comments,
                goal_status: goal.goal.goal_status,
                last_check_in: goal.goal.last_check_in
            })
            comments = JSON.parse(goal.goal_comments)
        })
        setGoals(goalData)
        setCheckinGoalData(goalData)
        setComments(comments)
    }

    const getRowClassName = useCallback((params) => {

        if (params.row.goal_status === 'archived') {
            return 'disabled-row';
        } else {
            return '';
        }
    }, [goals, checkin]);

    const getDataGridStyles = () => ({
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
            backgroundColor: 'none'
        },
        '& .MuiDataGrid-cell:hover': {
            backgroundColor: 'none',
        },
        '& .goal-header': {
            backgroundColor: '#ccc',
        },
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
        },
        '& .disabled-row': {
            backgroundColor: '#ccc',
            color: 'rgba(100, 100, 100, 0.8)',
            cursor: 'not-allowed'

        },
        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    });

    useEffect(() => {
        if (!checkinId) {
            fetchGoals()
        } else {
            getCheckinGoals()
        }
    }, [checkin])

    return (
        <>
            <DataGrid
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false
                        }
                    }
                }}
                sx={getDataGridStyles}
                rows={goals}
                columns={columns}
                pageSize={10}
                paginationModel={{ page: 0, pageSize: 6 }}
                pageSizeOptions={[6]}
                rowsPerPageOptions={[10]}
                getRowClassName={getRowClassName}
                disableRowSelectionOnClick
                disableColumnResize
                disableColumnMenu
                disableColumnSorting
                isCellEditable={(params) => !params.row.goal_status.includes('archived')}
                onCellClick={(params) => {
                    if (params.field === 'check_in_goal_status') {
                        setEditingId(params.id)
                    }
                }}
                // onRowClick={(params) => setEditingId(params.id)}
                onCellEditCommit={() => setEditingId(null)}

            />
            <Dialog
                open={openDialogGoalComment}
                onClose={handleClose}
                fullWidth
                maxWidth='xl'
            >
                <DialogContent>
                    <DialogTitle sx={{ textAlign: 'center' }}>{goalSelectedToComment?.goalTitle}</DialogTitle>
                    <CommentsTimeline
                        setComment={setComment}
                        comment={comment}
                        setComments={setComments}
                        comments={comments}
                        setCheckinGoalData={setCheckinGoalData}
                        checkinGoalData={checkinGoalData}
                        goalSelectedToComment={goalSelectedToComment}
                    />
                </DialogContent>
            </Dialog>
        </>

    )
}

export default CheckinGoals
