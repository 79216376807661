import React from 'react';
import RoutesComponent from './components/RoutesComponent';
import { SlackProvider } from './context/SlackUserInfoContext.js';
import { EmployeesProvider } from './context/UsersContext.js';
import { PeopleBuilderProvider } from './context/PeopleBuilderContext.js';
import { GoalsProvider } from './context/GoalsContext.js';
import { CheckInProvider } from './context/CheckInContexts.js';
import SnackbarAlert from './components/SnackbarAlert';
import { OrgUnitPathsProvider } from './context/OrgUnitPathsContext.js';

const App = () => {

    return (
        <>
            <SnackbarAlert />
            <OrgUnitPathsProvider >
                <CheckInProvider>
                    <GoalsProvider>
                        <PeopleBuilderProvider>
                            <SlackProvider>
                                <EmployeesProvider>
                                    <RoutesComponent />
                                </EmployeesProvider>
                            </SlackProvider>
                        </PeopleBuilderProvider>
                    </GoalsProvider>
                </CheckInProvider>
            </OrgUnitPathsProvider>

        </>
    );
}

export default App;