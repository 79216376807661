import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
    Divider, ImageListItem, ImageList, Button, Paper, Card, CardContent, Container,
    FormGroup, Stack, TextField, Autocomplete, Switch, Typography, Grid, useMediaQuery,
    Chip, Snackbar, Alert, Box, Tooltip
} from '@mui/material';
import GoalSteps from './GoalSteps';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import api from '../apis/api';
import { useParams } from 'react-router-dom';
import BackdropLoader from './common/BackdropLoader.jsx'
import { useNavigate, use } from 'react-router-dom';
import moment from 'moment';
import CommentsTimeline from './common/CommentsTimeline.jsx';
import SideBarOnGoalForm from './SideBarOnGoalForm.jsx';
import { GoalsContext } from '../context/GoalsContext.js';


const GoalForm = () => {
    const navigate = useNavigate()
    const { getGoals } = useContext(GoalsContext)
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null);
    const [checked, setChecked] = useState(false)
    const [checkedApproved, setCheckedApproved] = useState(false)
    const [isCoach, setIsCoach] = useState(false)
    const user = localStorage.getItem('user')
    const [loggedUser] = useState(JSON.parse(user))
    const { peopleBuilderId } = useParams()
    const [goalId, setGoalId] = useState(useParams().goalId)
    const [userPeopleBuilder, setUserPeopleBuilder] = useState([])
    const [goalDisabled, setGoalDisabled] = useState(false)
    const [steps, setSteps] = useState([
        {
            step_order: 0,
            label: 'Step 1',
            step: ''
        }
    ])
    const [comment, setComment] = useState('')
    const [comments, setComments] = useState([])
    const [goalData, setGoalData] = useState({
        people_builder_id: '',
        goal_name: '',
        goal_term: '',
        goal_importance: '',
        goals_etro: '',
        relationship: '',
        target_date: null,
        goal_approval: '',
        goal_status: 'Draft Goal',
        checkin_status: '',
        comments_id: '',
        steps: [],
        goal_approved_date: null,
        comments: null,

    })
    const [openSnackbar, setOpenSnackbar] = React.useState(false)
    const [severytypeSnackbar, setSeverytypeSnackbar] = React.useState('')
    const [messageSnackbar, setMessageSnackbar] = React.useState('')
    const [goalNameEmpty, setGoalNameEmpty] = useState({ error: false, errorText: '' })
    const [goalTermEmpty, setGoalTermEmpty] = useState({ error: false, errorText: '' })
    const [goalImportanceEmpty, setGoalImportanceEmpty] = useState({ error: false, errorText: '' })
    const [goalEtroEmpty, setGoalEtroEmpty] = useState({ error: false, errorText: '' })
    const [goalRelationshipEmpty, setGoalRelationshipEmpty] = useState({ error: false, errorText: '' })
    const [goalTargetDateEmpty, setGoalTargetDateEmpty] = useState({ error: false, errorText: '' })
    const [stepsEmpty, setStepsEmpty] = useState({ error: false, errorText: '' })

    const openAlert = (message, type) => {
        setOpenSnackbar(true)
        setMessageSnackbar(message)
        setSeverytypeSnackbar(type)
    }

    const updateGoalData = (key, value) => {

        if (key === 'steps') {
            setGoalData(prevData => ({ ...prevData, steps: value }))
        } else {
            setGoalData(prevData => ({ ...prevData, [key]: value }))
        }
    }

    const handleClose = () => {
        setOpenSnackbar(false)
    }

    const goBack = () => {
        navigate(-1)
    }

    const hasEmptyFields = () => {
        let hasEmptyFields = false
        const fieldsFromgoalAsApprovalToCheck = ['goal_name', 'goal_term', 'goal_importance', 'goals_etro', 'relationship', 'target_date', 'steps']

        Object.keys(goalData).map(goalDataKey => {

            if (fieldsFromgoalAsApprovalToCheck.includes(goalDataKey)) {

                if (goalDataKey === 'goal_name' && !goalData[goalDataKey]) {
                    setGoalNameEmpty({ error: true, errorText: 'Goal Name cannot be empty' });
                    hasEmptyFields = true
                }

                if (goalData.goal_status !== 'Draft Goal') {

                    if (goalDataKey === 'goal_term' && !goalData[goalDataKey]) {
                        setGoalTermEmpty({ error: true, errorText: 'Goal Term cannot be empty' });
                        hasEmptyFields = true
                    }

                    if (goalDataKey === 'goal_importance' && !goalData[goalDataKey]) {
                        setGoalImportanceEmpty({ error: true, errorText: 'Goal Importance cannot be empty' });
                        hasEmptyFields = true
                    }

                    if (goalDataKey === 'goals_etro' && !goalData[goalDataKey]) {
                        setGoalEtroEmpty({ error: true, errorText: 'Goal ETRO cannot be empty' });
                        hasEmptyFields = true
                    }

                    if (goalDataKey === 'relationship' && !goalData[goalDataKey]) {
                        setGoalRelationshipEmpty({ error: true, errorText: 'Goal relationship cannot be empty' });
                        hasEmptyFields = true
                    }

                    if (goalDataKey === 'target_date' && !goalData[goalDataKey]) {
                        setGoalTargetDateEmpty({ error: true, errorText: 'Goal Target Date cannot be empty' });
                        hasEmptyFields = true
                    }

                    if (goalDataKey === 'steps' && !goalData[goalDataKey].length) {
                        // let hasStepText = goalData[goalDataKey].filter(step => !step.step)

                        // if (hasStepText.length) {
                        setStepsEmpty({ error: true, errorText: 'Steps cannot be empty' });
                        hasEmptyFields = true
                        // }

                    }

                }

            }

        })

        return hasEmptyFields
    }

    const createGoal = async () => {

        console.log('goalData', goalData)

        // setOpenBackdrop(true)

        if (hasEmptyFields()) {
            setOpenBackdrop(false)
            return
        }

        return

        try {
            await api.post('/goals', goalData)
            setOpenBackdrop(false)
            openAlert('Goal created successfully', 'success')
            setTimeout(() => {
                navigate(-1)
            }, 1500)
            getGoals()
        } catch (error) {
            setOpenBackdrop(false)
            openAlert('Something was wrong', 'error')
            console.log(error)
        }
    }

    const handleSwitchChange = (e, newValue) => {
        setChecked(newValue)
        updateGoalData('goal_approval', newValue)
        updateGoalData('goal_status', newValue ? 'Approval' : 'Draft Goal')
    }

    const handleSwitchApprovedChange = (e, newValue) => {
        setCheckedApproved(!newValue)
        updateGoalData('goal_status', newValue ? 'Active' : 'Needs Revision')
    }

    const getUserGoal = async () => {

        if (!goalId) {
            return
        }

        setOpenBackdrop(true)

        try {
            const response = await api.get(`/goals`)
            const responseUserGoals = response.data.filter(goal =>
                goal.id == goalId
            )

            setIsCoach(responseUserGoals[0]?.people_builder.coach_id === loggedUser.id)

            try {
                const response = await api.get(`/steps`)
                const step = response.data.filter(step => step.goal_id === responseUserGoals[0].id)
                step.length ? setSteps(step) : ''
                setOpenBackdrop(false)
            } catch (error) {
                setOpenBackdrop(false)
                console.log(error)
            }

            updateGoalData('people_builder_id', responseUserGoals[0]?.people_builder_id)
            updateGoalData('goal_name', responseUserGoals[0]?.goal_name)
            updateGoalData('goal_term', responseUserGoals[0]?.goal_term)
            updateGoalData('goal_importance', responseUserGoals[0]?.goal_importance)
            updateGoalData('goals_etro', responseUserGoals[0]?.goals_etro)
            updateGoalData('relationship', responseUserGoals[0]?.relationship)
            updateGoalData('goal_approval', responseUserGoals[0]?.goal_approval)
            setSelectedDate(responseUserGoals[0]?.target_date ? moment(responseUserGoals[0]?.target_date?.split('T')[0]) : null)
            setChecked(responseUserGoals[0]?.goal_approval)
            setCheckedApproved(responseUserGoals[0]?.goal_approved_date !== null)
            updateGoalData('goal_approved_date', responseUserGoals[0]?.goal_approved_date)
            updateGoalData('comments', JSON.parse(responseUserGoals[0]?.comments))
            setComments(JSON.parse(responseUserGoals[0]?.comments) || [])

            if (responseUserGoals[0]?.goal_status === 'Active') {
                updateGoalData('goal_status', 'Active')
                setGoalDisabled(true)
            } else if (responseUserGoals[0]?.goal_status === 'Needs Revision') {
                updateGoalData('goal_status', 'Needs Revision')
                setGoalDisabled(false)
                setChecked(false)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const getUserPeopleBuilder = async () => {
        try {
            const response = await api.get(`/people-builders`)
            const responseUserPeopleBuilder = response.data.filter(pb =>
                pb.id == peopleBuilderId
            )
            setUserPeopleBuilder(responseUserPeopleBuilder)
            updateGoalData('people_builder_id', responseUserPeopleBuilder[0]?.id)
        } catch (error) {
            console.log(error)
        }
    }

    const updateGoalComments = useCallback(async () => {
        setOpenBackdrop(true)

        const addComments = [...comments, {

            comment: comment,
            commentDate: new Date().toLocaleDateString('en-ca', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            }),
            name: loggedUser.full_name,
            avatar: loggedUser.photo_url
        }]

        const dataToSave = { comments: JSON.stringify(addComments) }
        setComments(addComments)

        try {
            await api.post(`/goals/${goalId}`, dataToSave)
            openAlert('Goal comments updated successful', 'success')
            setOpenBackdrop(false)
            setComment('')
        } catch (error) {
            console.log(error)
            setOpenBackdrop(false)
            openAlert('Something was wrong on update goal', 'error')
        }

    }, [comments, comment])

    const updateGoal = async (id) => {
        setOpenBackdrop(true)

        let data = Object.fromEntries(
            Object.entries(goalData).filter(([_, v]) => {

                if (_ === 'goal_approved_date' || _ === 'comments') {
                    return true
                }

                return v !== undefined &&
                    v !== null &&
                    v !== '' &&
                    (typeof v !== 'object' || Object.keys(v).length !== 0)
            })
        )

        const dataToSave = { ...data, comments: JSON.stringify(comments) }

        try {
            await api.post(`/goals/${id}`, dataToSave)
            openAlert('Goal updated successful', 'success')
            setOpenBackdrop(false)
            getUserGoal()

            setTimeout(() => {
                navigate(-1)
            }, 1500);

        } catch (error) {
            console.log(error)
            setOpenBackdrop(false)
            openAlert('Something was wrong on update goal', 'error')
        }

    }

    const matchesSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const handleAddComment = useCallback(() => {

        setComments(prevState => ([...prevState, {

            comment: comment,
            commentDate: new Date().toLocaleDateString('en-ca', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            }),
            name: loggedUser.full_name,
            avatar: loggedUser.photo_url
        }]))
        updateGoalData('comments', JSON.stringify(comments))
        setComment('')
    })

    useEffect(() => {
        getUserPeopleBuilder()
        getUserGoal()
    }, [])

    return (

        <Container maxWidth={matchesSmallScreen ? 'sm' : 'xl'} >
            <Stack direction={matchesSmallScreen ? 'column' : 'row'} marginRight='0'>
                {openBackdrop && (
                    <BackdropLoader openBackdrop={open} />
                )}
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={openSnackbar} autoHideDuration={3000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity={severytypeSnackbar}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {messageSnackbar}
                    </Alert>
                </Snackbar>
                <Card sx={{ mt: 4, maxHeight: '85vh', overflow: 'auto', width: '100%' }}>
                    <CardContent>
                        <Stack
                            direction='row'
                            spacing={4}
                            alignItems='center'
                            alignContent='space-between'
                        >
                            <Grid item display={matchesSmallScreen ? 'none' : 'block'}>
                                <ImageList>
                                    <ImageListItem sx={{ width: '10rem' }}>
                                        <img src="https://iili.io/J1MU5vV.png" />
                                    </ImageListItem>
                                </ImageList>
                            </Grid>
                            <Box sx={{ padding: 2, width: '100%' }}>
                                <Stack
                                    direction='row'
                                    spacing={4}
                                    justifyContent='space-between'
                                    alignContent='center'
                                >
                                    <Grid item sm={12}>
                                        <Typography variant='span'>Player:
                                            <Chip label={userPeopleBuilder[0]?.employee?.full_name || loggedUser.full_name} />
                                        </Typography>
                                    </Grid>
                                    <Typography variant='span'>Coach:
                                        <Chip label={userPeopleBuilder[0]?.employee?.coach || loggedUser.coach} />
                                    </Typography>
                                    <Typography variant='span'>Year: {userPeopleBuilder[0]?.year || new Date().getFullYear()}</Typography>
                                </Stack>
                            </Box>
                        </Stack>
                        <Container>
                            <FormGroup sx={{ mt: 2 }}>
                                <Stack spacing={4}>
                                    {/* <Stack direction='row' spacing={2}> */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                disabled={goalDisabled}
                                                id="goal-name outlined-basic"
                                                label="Goal Name"
                                                variant='standard'
                                                value={goalData.goal_name}
                                                fullWidth
                                                onChange={(e) => {
                                                    setGoalNameEmpty({ error: false, errorText: '' })
                                                    updateGoalData('goal_name', e.target.value)
                                                }}
                                                helperText={goalNameEmpty.errorText}
                                                error={goalNameEmpty.error}
                                                required
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                fullWidth
                                                disabled={goalDisabled}
                                                options={[
                                                    'Short Term (1-3 Months)',
                                                    'Annual Term (12 Months)',
                                                    'Medium Term (1-2 Years)',
                                                    'Long Term (2-5 Years)',
                                                    'Life Long (5+ Years)'
                                                ]}
                                                label="Goal Term"
                                                variant='standard'
                                                value={goalData?.goal_term || null}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        helperText={goalTermEmpty.errorText}
                                                        error={goalTermEmpty.error}
                                                        required={goalData.goal_status !== 'Draft Goal' ? true : false}
                                                        label="Goal Term"
                                                        variant="standard"
                                                    />
                                                )}
                                                onChange={(e, newValue) => {
                                                    setGoalTermEmpty({ error: false, errorText: '' })
                                                    updateGoalData('goal_term', newValue)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        disabled={goalDisabled}
                                        id="goal-important outlined-multiline-static"
                                        label="Goal Importance"
                                        multiline
                                        rows={4}

                                        variant='outlined'
                                        value={goalData.goal_importance}
                                        onChange={(e) => {
                                            setGoalImportanceEmpty({ error: false, errorText: '' })
                                            updateGoalData('goal_importance', e.target.value)
                                        }}
                                        helperText={goalImportanceEmpty.errorText}
                                        error={goalImportanceEmpty.error}
                                        required={goalData.goal_status !== 'Draft Goal' ? true : false}
                                    />
                                    <Grid item xs={12} sm={4}>
                                        <Autocomplete
                                            fullWidth
                                            disabled={goalDisabled}
                                            value={goalData?.goals_etro || null}
                                            options={[
                                                'Culture of Accountability & High Performing Teams',
                                                'Exceptional Delivery',
                                                'High Level of Quality',
                                                'Industry Partners',
                                                'Innovation at Every Level',
                                                'Lean Process',
                                                'People First'
                                            ]}
                                            label="Goals ETRO"
                                            variant='standard'
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label="Goals ETRO"
                                                    variant="standard"
                                                    helperText={goalEtroEmpty.errorText}
                                                    error={goalEtroEmpty.error}
                                                    required={goalData.goal_status !== 'Draft Goal' ? true : false}
                                                />
                                            }
                                            onChange={(e, newValue) => {
                                                setGoalEtroEmpty({ error: false, errorText: '' })
                                                updateGoalData('goals_etro', newValue)
                                            }}

                                        />
                                    </Grid>
                                    <TextField
                                        disabled={goalDisabled}
                                        id="goal-related outlined-multiline-static"
                                        label="Expand on the relation between your Goal and the related Strategic Goal:"
                                        multiline
                                        rows={4}
                                        variant='outlined'
                                        value={goalData.relationship}
                                        onChange={(e) => updateGoalData('relationship', e.target.value)}
                                        helperText={goalRelationshipEmpty.errorText}
                                        error={goalRelationshipEmpty.error}
                                        required={goalData.goal_status !== 'Draft Goal' ? true : false}

                                    />
                                    <GoalSteps
                                        disabled={goalDisabled}
                                        setGoalId={setGoalId}
                                        goalId={goalId}
                                        setSteps={setSteps}
                                        steps={steps}
                                        onSaveSteps={updateGoalData}
                                        goalData={goalData}
                                        stepsEmpty={stepsEmpty}
                                        setStepsEmpty={setStepsEmpty}
                                    />
                                    <Stack spacing={4} >
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                        >
                                            <DatePicker
                                                label="Target Date"
                                                disabled={goalDisabled}
                                                sx={{ width: '100%' }}
                                                value={selectedDate}
                                                onChange={(newValue) => {
                                                    setSelectedDate(newValue)
                                                    updateGoalData('target_date', newValue.format().split('T')[0])
                                                }}
                                                views={['year', 'month', 'day']}
                                                required
                                                slotProps={{
                                                    textField: {
                                                        helperText: goalTargetDateEmpty.errorText,
                                                        error: goalTargetDateEmpty.error,
                                                        required: goalData.goal_status !== 'Draft Goal' ? true : false
                                                    }
                                                }}

                                                format='YYYY-MM-DD'
                                            />
                                        </LocalizationProvider>
                                        {!isCoach && !goalDisabled ? (
                                            <Paper
                                                id='draft-goal-and-approval'
                                                sx={{ padding: 2 }}
                                                elevation={4}
                                            >
                                                <FormGroup sx={{ alignContent: 'center' }}>
                                                    <Stack direction='row' spacing={2} alignItems='center'>
                                                        <Button
                                                            sx={{ backgroundColor: !checked ? 'orange' : 'grey', }}
                                                            disabled={goalDisabled}
                                                            variant="contained"
                                                            onClick={() => {
                                                                setChecked(false)
                                                                updateGoalData('goal_approval', false)
                                                                updateGoalData('goal_status', 'Draft Goal')
                                                                setGoalTermEmpty({ error: false, errorText: '' })
                                                                setGoalImportanceEmpty({ error: false, errorText: '' })
                                                                setGoalEtroEmpty({ error: false, errorText: '' })
                                                                setGoalRelationshipEmpty({ error: false, errorText: '' })
                                                                setGoalTargetDateEmpty({ error: false, errorText: '' })

                                                            }}
                                                        >
                                                            DRAFT GOAL
                                                        </Button>
                                                        <Switch
                                                            color="default"
                                                            disabled={goalDisabled}
                                                            checked={checked}
                                                            onChange={handleSwitchChange}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            sx={{ backgroundColor: checked ? 'green' : 'grey' }}
                                                            disabled={goalDisabled}
                                                            onClick={() => {
                                                                setChecked(true)
                                                                updateGoalData('goal_approval', true)
                                                                updateGoalData('goal_status', 'Approval')
                                                            }}
                                                        >
                                                            READY FOR APPROVAL
                                                        </Button>
                                                    </Stack>
                                                </FormGroup>
                                            </Paper>
                                        ) : (
                                            <Paper
                                                id='revision-and-approved'
                                                sx={{ padding: 2 }}
                                                elevation={4}
                                            >
                                                <FormGroup sx={{ alignContent: 'center' }}>
                                                    <Stack direction='row' spacing={2} alignItems='center'>
                                                        <Button
                                                            sx={{ backgroundColor: !checked ? 'orange' : 'grey', }}
                                                            disabled={!isCoach ? goalDisabled : false}
                                                            variant="contained"
                                                            onClick={() => {
                                                                setCheckedApproved(false)
                                                                updateGoalData('goal_status', 'Needs Revision')
                                                                updateGoalData('goal_approved_date', null)
                                                            }}
                                                        >
                                                            BACK TO REVISION
                                                        </Button>
                                                        <Switch
                                                            color="default"
                                                            disabled={!isCoach ? goalDisabled : false}
                                                            checked={checkedApproved}
                                                            onChange={handleSwitchApprovedChange}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            sx={{ backgroundColor: checked ? 'green' : 'grey' }}
                                                            disabled={!isCoach ? goalDisabled : false}
                                                            onClick={() => {
                                                                setCheckedApproved(true)
                                                                updateGoalData('goal_status', 'Active')
                                                                updateGoalData('goal_approved_date', new Date())
                                                            }}
                                                        >
                                                            GOAL APPROVED
                                                        </Button>
                                                    </Stack>
                                                </FormGroup>
                                            </Paper>
                                        )}
                                        <CommentsTimeline
                                            setComment={setComment}
                                            comment={comment}
                                            setComments={setComments}
                                            comments={comments}
                                            loggedUser={loggedUser}
                                            handleAddComment={handleAddComment}
                                            updateGoalComments={updateGoalComments}
                                        />
                                    </Stack>
                                    <Divider />
                                    <Stack direction='row' spacing={2} justifyContent='right'>
                                        <Button
                                            onClick={goBack}
                                            color='error'
                                            variant="contained">Cancel</Button>
                                        <Tooltip title={!isCoach && goalDisabled ? 'This goal is not update by player anymore' : ''}>
                                            <Stack>
                                                <Button
                                                    variant="contained"
                                                    disabled={!isCoach ? goalDisabled : false}
                                                    color='success'
                                                    type='submit'
                                                    onClick={() => {
                                                        goalId ? updateGoal(goalId, goalData) : createGoal()
                                                    }}
                                                >
                                                    Save
                                                </Button>
                                            </Stack>
                                        </Tooltip>
                                    </Stack>
                                </Stack>
                            </FormGroup>
                        </Container>
                    </CardContent>
                </Card>
                <SideBarOnGoalForm />
            </Stack>
        </Container >
    )


}

export default GoalForm