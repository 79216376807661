import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GoogleAuth from './GoogleAuth.jsx'
import NotAuth from './NotAuth.jsx';
import OrgUnitPaths from './OrgUnitPath.jsx';
import Swagger from './Swagger.jsx';
import GoalForm from './GoalForm.jsx'
import PeopleBuilderSettings from './PeopleBuilderSettings.jsx';
import PeopleBuilder from './PeopleBuilder.jsx'
import SlackAuth from './SlackAuth.jsx';
import SlackCallback from './SlackCallback.jsx';
import Navbar from './Navbar.jsx';
import CheckInForm from './CheckInForm.jsx';
import EtroTeamPage from '../pages/EtroTeamPage.jsx';
import EmployeeProfilePage from '../pages/EmployeeProfilePage.jsx';
import TitlesPage from '../pages/TitlesPage.jsx';
import DepartmentsPage from '../pages/DepartmentsPage.jsx';
import WorkLocationsPage from '../pages/WorkLocationsPage.jsx';

const RoutesComponent = () => {

    const [slackToken, setSlackToken] = useState(localStorage.getItem('slackUserData'))
    const [googleToken, setGoogleToken] = useState(localStorage.getItem('user'))
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    const checkToken = () => {

        if (code) {
            setSlackToken(true)
        }

        if (!slackToken) {
            console.log('Slack token not found')
            return <SlackAuth />
        }
    }

    useEffect(() => {
        checkToken()
    }, [slackToken])

    return (
        <Router>
            {!googleToken || !slackToken ? (
                <Routes>
                    <Route path="/*" element={<GoogleAuth />} />
                    <Route path="/slack-auth" element={<SlackAuth />} />
                    <Route path="/not-auth" element={<NotAuth />} />
                    <Route path="/slack-code" element={<SlackCallback />} />
                </Routes>
            ) : (
                !slackToken ? <SlackAuth /> : (
                    <>
                        <Navbar />
                        <Routes>
                            <Route path="/" element={<GoogleAuth />} />
                            <Route path="/not-auth" element={<NotAuth />} />
                            <Route path="/work-locations" element={<WorkLocationsPage />} />
                            <Route path="/employee/profile/:userId" element={<EmployeeProfilePage />} />
                            <Route path="/org-unit-path" element={<OrgUnitPaths />} />
                            <Route path="/departments" element={<DepartmentsPage />} />
                            <Route path="/titles" element={<TitlesPage />} />
                            <Route path="/api-docs" element={<Swagger />} />
                            <Route path="/goal-form/people-builder/:peopleBuilderId?" element={<GoalForm />} />
                            <Route path="/goal-form/people-builder/:peopleBuilderId/goal/:goalId" element={<GoalForm />} />
                            <Route path="/people-builder-settings" element={<PeopleBuilderSettings />} />
                            <Route path="/personal-people-builder/:userId" element={<PeopleBuilder />} />
                            <Route path="/team-people-builder/:userId" element={<PeopleBuilder />} />
                            <Route path="/company-people-builder/:userId" element={<PeopleBuilder />} />
                            <Route path="/slack-auth" element={<SlackAuth />} />
                            <Route path="/slack-code" element={<SlackCallback />} />
                            <Route path="/new-check-in/:playerId?" element={<CheckInForm />} />
                            <Route path="/new-mid-check-in/:playerId?" element={<CheckInForm />} />
                            <Route path="/new-end-check-in/:playerId?" element={<CheckInForm />} />
                            <Route path="/check-in/:checkinId?" element={<CheckInForm />} />
                            <Route path="/mid-year-check-in/:checkinId?" element={<CheckInForm />} />
                            <Route path="/end-year-check-in/:checkinId?" element={<CheckInForm />} />
                            <Route path="/employee" element={<EtroTeamPage />} />
                        </Routes>
                    </>
                )
            )}
        </Router>
    )
}
export default RoutesComponent