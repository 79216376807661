import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import {
    Button, Card, CardContent, Container, Avatar, Typography, Stack,
    Autocomplete, TextField, Dialog, DialogTitle, DialogContent,
    DialogContentText, DialogActions, Tooltip, Checkbox, FormGroup, FormControlLabel,
    Chip, Snackbar, Alert, FormLabel
} from '@mui/material';
import { EmployeesContext } from '../context/UsersContext.js';
import styles from '../css/Profile-page.module.css'
import { useNavigate, useParams } from 'react-router-dom';
import Strengths from './Strengths.jsx';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import api from '../apis/api.js'
import { createTitle, getTitles } from '../apis/titlesAPI.js';
import { getDepartmentAPI, saveNewDepartmentAPI } from '../apis/departmentsAPI.js';
import BackdropLoader from './common/BackdropLoader.jsx'
import { SnackbarContext } from '../context/SnackbarContext.js';

const Profile = () => {
    const { employees, fetchUsers } = useContext(EmployeesContext)
    const { openAlert } = useContext(SnackbarContext)
    const { userId } = useParams()
    const [openBackdrop, setOpenBackdrop] = useState(true)
    const [showAddPhotoIcon, setShowAddPhotoIcon] = useState(false);
    const [photoOpacity, setPhotoOpacity] = useState(1);
    const [managerLabelChecked, setManagerLabelChecked] = useState(true)
    const [open, setOpen] = useState(false);
    const [openChangePhoto, setOpenChangePhoto] = useState(false);
    const [openDialogNewDepartment, setOpenDialogNewDepartment] = useState(false)
    const [openDialogNewTitle, setOpenDialogNewTitle] = useState(false)
    const [employee, setEmployee] = useState([])
    const [newDepartment, setNewDepartment] = useState('')
    const [newTitle, setNewTitle] = useState('')
    const [selectedFields, setSelectedFields] = useState({
        department: null,
        departmentId: null,
        title: null,
        titleId: null,
        coach: null,
        manager: null,
        workLocation: null,
        workLocationId: null,
        phoneType: '',
        phone: ''
    })
    const [fileName, setFileName] = useState('')
    const [image, setImage] = useState('')
    const [coachList, setCoachList] = useState([]);
    const [managerList, setManagerList] = useState([]);
    const [titles, setTitles] = useState([{ label: 'New Title', id: 'new' }])
    const [departments, setDepartments] = useState([{ label: 'New Department', id: 'new' }])
    const [workLocations, setWorkLocations] = useState([])
    const [isAdmin, setIsAdmin] = useState(false)
    const [disabledField, setDisabledField] = useState(false)
    const navigate = useNavigate()
    const token = localStorage.getItem('token')
    const fileInputRef = useRef(null)
    const [disabledDirectManager, setDisabledDirectManager] = useState(managerLabelChecked)
    const [loading, setLoading] = useState(true)

    const handleClick = () => {
        fileInputRef.current.click();
    }
    var coachs = []
    var coachsValues = []
    var managers = []
    var managersValues = []

    const bringTitles = async () => {
        const titles = await getTitles()
        titles?.sort((a, b) => a.title.localeCompare(b.title))
        titles?.map((title) => {
            setTitles(prevState => ([...prevState, { label: title.title, id: title.id }]))
        })
    }

    const updateTitleVcard = async () => {
        const data = { title: selectedFields.title, user: employee.full_name }

        try {
            const response = await api.post('/vcard', data)
        } catch (error) {
            console.log(error)
        }
    }

    const createNewTitle = async (newTitle) => {
        setSelectedFields(prev => ({ ...prev, title: newTitle }))
        handleCloseDialog()
        setOpenBackdrop(true)
        const response = await createTitle(newTitle)
        openAlert(response.message, response && response.status === 200 ? 'success' : 'error')
        setOpenBackdrop(false)
    }

    const getDepartments = useCallback(async () => {
        const departments = await getDepartmentAPI()
        departments?.sort((a, b) => a.department.localeCompare(b.department))
        departments?.map((department) => {
            setDepartments((prevStates) => ([...prevStates, { label: department.department, id: department.id }]))
        })
    })

    const createNewDepartment = async (newDepartment) => {
        handleCloseDialog()
        setOpenBackdrop(true)
        const response = await saveNewDepartmentAPI(newDepartment)
        openAlert(response.message, response && response.status === 200 ? 'success' : 'error')
        response && response.status === 200 ? setSelectedFields(prev => ({ ...prev, department: newDepartment })) : ''
        setOpenBackdrop(false)
    }

    const getWorkLocations = useCallback(async () => {
        setWorkLocations([])
        try {

            const response = fetch(`${process.env.REACT_APP_BASE_URL}/work-locations`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(async res => {
                var workLocations = await res.json()
                workLocations.sort((a, b) => a.building_name.localeCompare(b.building_name))
                workLocations.map((location) => {
                    setWorkLocations((prevStates) => ([...prevStates, { label: location.building_name, id: location.id }]))
                })
            })
        } catch (e) {
            console.log(e)
        }
    })

    const findCoachEmail = (coachName) => {
        const coachEmail = employees.find(employee => employee.full_name?.replaceAll('Coach: ', '') === coachName?.replaceAll('Coach: ', ''))
        return coachEmail?.email ?? ''
    }

    const handleClose = () => {
        setOpenSnackbar(false)
    }

    const handleUpdateEmployee = useCallback(async () => {
        setOpenBackdrop(true)

        const updatedEmployeeData = {
            userId: userId,
            department_id: selectedFields?.departmentId ?? '',
            departmentText: selectedFields?.department ?? '',
            title_id: selectedFields?.titleId ?? '',
            titleText: selectedFields?.title ?? '',
            coach: selectedFields?.coach ?? '',
            direct_manager: selectedFields?.manager ?? '',
            work_location_id: selectedFields?.workLocationId ?? '',
            work_location_text: selectedFields?.workLocation ?? '',
            etro_type_phone: selectedFields?.phoneType ?? '' === 'ETRO Phone' ? 'work' : 'mobile',
            phone: selectedFields?.phone ?? ''
        }

        const employeeSelected = employees.find(employee => employee.id === userId)
        const coachSelected = employees.find(employee => employee.full_name === selectedFields?.coach)

        const peopleBuilderData = {
            coach_id: coachSelected.id
        }

        try {
            const response = await api.put(`/user/update/${employeeSelected.id}`, updatedEmployeeData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                try {
                    const pbResponse = await api.patch(`/people-builders/${employeeSelected.id}`, peopleBuilderData)
                    updateTitleVcard()
                } catch (error) {
                    console.log(error)
                }
            }

            if (response.status === 200) {
                openAlert('Employee updated successful', 'success')
                fetchUsers()
                navigate('/employee')
            } else {
                console.error('Something was wrong:', response);
                openAlert(response.message, 'error')
            }

            setOpenBackdrop(false)

        } catch (error) {
            console.error('Something was wrong:', error.message);
            openAlert(error.message, 'error')
            setOpenBackdrop(false)
        }

        // Updating Google Admin
        try {
            const response = await api.put(`/user/update/google/${userId}`, updatedEmployeeData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

        } catch (error) {
            console.error('Something was wrong:', error);
        }

    }, [selectedFields, employees])

    const employeeFitered = useCallback(() => {

        employees.map(employee => {

            if (!employee.etro_team) {
                return
            }

            if (!coachsValues.includes(employee.full_name) && employee.full_name !== '') {
                coachsValues.push(employee.full_name)
                coachs.push({ label: employee.full_name, id: employee.id })
            }

            if (!managersValues.includes(employee.full_name) && employee.full_name) {
                managersValues.push(employee.full_name)
                managers.push({ label: employee.email, id: employee.id })
            }

        })

        coachs.sort((a, b) => a?.label?.localeCompare(b?.label))
        workLocations.sort((a, b) => a?.label?.localeCompare(b?.label))

        setCoachList(coachs)
        setManagerList(managers)

    }, [employees])

    const updateSelectedField = (field, value) => {
        setSelectedFields(prevState => ({ ...prevState, [field]: value }));
    };

    const fetchAndFilterEmployeeInfo = useCallback(async () => {

        try {

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            var users = await response.json()
            const employee = users.find(emp => emp.id === userId);
            const employeeFixed = employees.find(emp => emp.id === userId);
            setOpenBackdrop(false)

            if (employee) {
                updateSelectedField('departmentId', employee.departmentId);
                updateSelectedField('department', employeeFixed?.department?.department ?? null);
                updateSelectedField('titleId', employee.titleId);
                updateSelectedField('title', employeeFixed?.title?.title ?? null);
                updateSelectedField('coach', employee.coach);
                updateSelectedField('manager', employee.direct_manager);
                updateSelectedField('workLocationId', employee.work_location_id);
                updateSelectedField('workLocation', employeeFixed?.work_location?.building_name ?? null);
                updateSelectedField('phoneType', employee.etro_type_phone === 'work' ? 'ETRO Phone' : 'Personal Phone');
                updateSelectedField('phone', employee.phone);
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)

        }

    }, [employees, userId]);

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClickOpenPhoto = () => {
        setOpenChangePhoto(true);
    }

    const handleClickOpenDialogNewDepartment = () => {
        setOpenDialogNewDepartment(true)
    }

    const handleCloseDialog = () => {
        setOpen(false);
        setOpenChangePhoto(false);
        setOpenDialogNewDepartment(false);
        setOpenDialogNewTitle(false);
        setFileName('')
    }

    const suspendUser = useCallback(async () => {
        setOpen(false)
        setOpenBackdrop(true)

        try {
            const response = await api.put(`/user/suspend/${employee.id}`, { suspended: !employee.suspended })

            if (response.status === 200) {
                setEmployee(response.data[0])
                fetchUsers()
                openAlert(`User ${employee.suspended ? 'Activated' : 'Suspended'} successful`, 'success')
            } else {
                openAlert('Oops! Something went wrong', 'error')
            }
            setOpenBackdrop(false)

        } catch (error) {
            openAlert('Oops! Something went wrong')
            console.error('Something was wrong:', error);
            setOpenBackdrop(false)
        }

    }, [employee])

    const updateProfilePhoto = async () => {
        setOpenBackdrop(true)
        setOpenChangePhoto(false)
        const slack = await changeSlackPhoto()
        await changeGooglePhoto()
        setOpenBackdrop(false)
    }

    const changeSlackPhoto = async (e) => {

        const formData = new FormData()
        formData.append('image', image)

        try {
            const response = await api.post(`/user/update-slack-photo/${employee.id}`, formData)

            if (response.status === 200) {
                openAlert('Slack Profile Photo was updated Successfully', 'success')
            } else {
                openAlert('Slack Profile Photo was not updated Successfully', 'error')
            }

            return response
        } catch (error) {
            console.log(error)
            openAlert(error.response.data.message, 'error')
        }
    }

    const changeGooglePhoto = async (e) => {
        const formData = new FormData()
        formData.append('image', image)
        formData.append('userData', JSON.stringify(employee))

        try {
            const response = await api.post('/change-google-photo', formData)
            if (response.status === 200) {
                openAlert('Google Profile Photo was updated Successfully', 'success')
            } else {
                openAlert('Google Profile Photo was not updated Successfully', 'error')
            }

            return response
        } catch (error) {
            openAlert(error.message, 'error')
        }
    }

    useEffect(() => {
        employeeFitered()
        const employee = employees.find(employee => employee.id === userId);

        if (employee) {
            setEmployee(employee)
            setIsAdmin(employee.is_admin || employee.is_delegated_admin)

            if (employee.is_admin || employee.is_delegated_admin) {
                setDisabledField(true)
                setDisabledDirectManager(true)
            }
        }

    }, [employeeFitered, userId, setSelectedFields])

    useEffect(() => {
        fetchAndFilterEmployeeInfo()
    }, [fetchAndFilterEmployeeInfo])

    useEffect(() => {
        bringTitles()
        getDepartments()
        getWorkLocations()
    }, [])

    const handleReturnToListUser = useCallback(() => {
        navigate('/employee')
    })

    return (
        <Container maxWidth="xl" >
            {loading ? (
                <BackdropLoader openBackdrop={true} />
            ) : (
                <>
                    {openBackdrop && (
                        <BackdropLoader openBackdrop={open} />
                    )}
                    <Card sx={{ mt: 4 }}>
                        <CardContent>
                            <Stack spacing={2}>
                                <Card>
                                    <CardContent>
                                        <Stack sx={{ 'alignItems': 'center' }} direction="row" spacing={2}>
                                            <Stack className={styles.withTotal} direction="row" justifyContent='space-between' alignItems='center' spacing={2}>
                                                <Stack direction='row' sx={{ alignItems: 'center' }}>
                                                    <Stack direction="row">
                                                        <Avatar
                                                            src={employee?.photo_url}
                                                            sx={{ width: 56, height: 56, opacity: photoOpacity }}
                                                            onMouseEnter={() => {
                                                                setShowAddPhotoIcon(true)
                                                                setPhotoOpacity(0.5)
                                                            }}
                                                            onMouseLeave={() => {
                                                                setShowAddPhotoIcon(false)
                                                                setPhotoOpacity(1)
                                                            }}
                                                        />
                                                        {showAddPhotoIcon && !isAdmin && (
                                                            <AddAPhotoIcon
                                                                style={{ margin: '0.4rem', cursor: 'pointer', fontSize: 40, position: 'absolute' }}
                                                                onMouseEnter={() => {
                                                                    setShowAddPhotoIcon(true)
                                                                    setPhotoOpacity(0.5)
                                                                }}
                                                                onMouseLeave={() => {
                                                                    setShowAddPhotoIcon(false)
                                                                    setPhotoOpacity(1)
                                                                }}
                                                                onClick={handleClickOpenPhoto}
                                                            >
                                                                <AddAPhotoIcon style={{ opacity: 0.5 }} />
                                                            </AddAPhotoIcon>
                                                        )}
                                                    </Stack>
                                                    <Stack spacing={2}>
                                                        <Stack direction='row' spacing={1}>
                                                            <Typography className={styles.name_employee} variant="subtitle2" gutterBottom>{employee?.full_name}</Typography>
                                                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                                {employee.is_admin || employee.is_delegated_admin && (
                                                                    <img style={{ alignContent: 'center', width: '20px' }} src='https://iili.io/Jazab4a.png' />
                                                                )}
                                                            </div>
                                                        </Stack>
                                                        <Typography className={styles.name_employee} variant="subtitle2" gutterBottom>{employee?.email}</Typography>
                                                    </Stack>
                                                    <Stack sx={{ ml: 2 }} spacing={1}>
                                                        <Chip
                                                            sx={{ backgroundColor: '#ffeb3b' }}
                                                            label={employee.etro_team ? 'ETRO Team' : 'Not ETRO Team'}
                                                        />
                                                        {/* {employee.is_admin || employee.is_delegated_admin && (
												<Chip
													sx={{ backgroundColor: '#ffeb3b' }}
													label='Admin'
												/>
											)} */}

                                                    </Stack>
                                                </Stack>
                                                <Strengths employeeId={employee.id} />
                                            </Stack>
                                        </Stack>
                                    </CardContent>
                                </Card>
                                <Stack spacing={2} direction='row' alignItems={'center'}>
                                    {departments.length > 0 && (
                                        <Autocomplete id="department"
                                            disabled={disabledField}
                                            options={departments}
                                            sx={{ width: '50%' }}
                                            value={departments.find(option => option.label === selectedFields.department) || null}

                                            onChange={(event, newValue) => {
                                                if (newValue?.id === 'new') {
                                                    handleClickOpenDialogNewDepartment()
                                                } else {
                                                    updateSelectedField('department', newValue?.label);
                                                    updateSelectedField('departmentId', newValue?.id);
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Department" variant="standard" />}

                                        />
                                    )}
                                    {titles.length > 0 && (
                                        <Autocomplete id="title"

                                            disabled={disabledField}
                                            options={titles}
                                            sx={{ width: '50%' }}
                                            value={titles.find(option => option.label === selectedFields.title) || null}
                                            onChange={(event, newValue) => {
                                                if (newValue?.id === 'new') {
                                                    setOpenDialogNewTitle(true)
                                                } else {
                                                    updateSelectedField('title', newValue?.label)
                                                    updateSelectedField('titleId', newValue?.id)
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Title" variant="standard" />}
                                        />
                                    )}
                                </Stack>
                                <Stack spacing={2} direction='row' alignItems={'center'}>
                                    {coachList.length > 0 && (
                                        <Autocomplete id="coach"

                                            disabled={disabledField}
                                            options={coachList}
                                            sx={{ width: '50%' }}
                                            value={coachList.find(option => option.label === selectedFields.coach) || null}
                                            onChange={(event, newValue) => {
                                                newValue ? updateSelectedField('coach', newValue?.label) : '';

                                                if (managerLabelChecked) {
                                                    updateSelectedField('manager', findCoachEmail(newValue?.label))
                                                }
                                            }}
                                            // renderInput={(params) => <TextField {...params} label="Coach" variant="standard" />}
                                            renderInput={(params) => (
                                                <>
                                                    <TextField
                                                        {...params}
                                                        label={
                                                            <FormGroup>
                                                                <Stack direction='row' spacing={4}>
                                                                    <FormLabel>Coach</FormLabel>
                                                                    <FormControlLabel
                                                                        sx={{ maxHeight: '20px' }}
                                                                        control={<Checkbox checked={managerLabelChecked} />}
                                                                        label="Direct Manager is the same as the Coach"
                                                                        onChange={(event) => {
                                                                            setManagerLabelChecked(event.target.checked)
                                                                            setDisabledDirectManager(event.target.checked)
                                                                            event.target.checked ? updateSelectedField('manager', findCoachEmail(selectedFields.coach)) : ''
                                                                        }}
                                                                    >
                                                                        <Checkbox color="primary" />
                                                                    </FormControlLabel>
                                                                </Stack>
                                                            </FormGroup>
                                                        }
                                                        variant="standard"
                                                    />
                                                </>
                                            )}
                                        />
                                    )}
                                    {managerList.length > 0 && (
                                        <Autocomplete id="manager"
                                            disabled={disabledDirectManager}
                                            options={managerList}
                                            variant="standard"
                                            sx={{ width: '50%' }}
                                            value={managerList.find(option => option.label === selectedFields.manager) || null}
                                            onChange={(event, newValue) => {
                                                updateSelectedField('manager', newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    onKeyDown={(e) => {
                                                        !managerLabelChecked ? '' : e.preventDefault()
                                                    }}
                                                    label='Direct Manager'
                                                    // label={
                                                    //     <FormGroup>
                                                    //         <FormControlLabel
                                                    //             sx={{ maxHeight: '20px' }}
                                                    //             control={<Checkbox checked={managerLabelChecked} />}
                                                    //             label="Direct Manager is the same as the Coach"
                                                    //             onChange={(event) => {
                                                    //                 setManagerLabelChecked(event.target.checked)
                                                    //                 event.target.checked ? updateSelectedField('manager', findCoachEmail(selectedFields.coach)) : ''
                                                    //             }}
                                                    //         >
                                                    //             <Checkbox color="primary" />
                                                    //         </FormControlLabel>
                                                    //     </FormGroup>
                                                    // }
                                                    variant="standard" />
                                            )}
                                        />
                                    )}
                                </Stack>
                                <Stack spacing={2} direction='row' alignItems={'center'} >
                                    {workLocations.length > 0 && (
                                        <Autocomplete
                                            id="work-location"
                                            disabled={disabledField}
                                            options={[{ label: 'New Work Location', id: 'new' }, ...workLocations]}
                                            sx={{ width: '60%' }}
                                            value={workLocations.find(option => option.label === selectedFields.workLocation) || null}
                                            onChange={(event, newValue) => {
                                                if (newValue?.id === 'new') {
                                                    navigate('/work-locations');
                                                } else {
                                                    updateSelectedField('workLocation', newValue?.label);
                                                    updateSelectedField('workLocationId', newValue?.id);
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Work Location" variant="standard" />}
                                        />
                                    )}
                                    <Autocomplete
                                        id="Phone-type"
                                        disabled={disabledField}
                                        options={['ETRO Phone', 'Personal Phone']}
                                        sx={{ width: '20%' }}
                                        value={selectedFields.phoneType || ''}
                                        onChange={(event, newValue) => {
                                            updateSelectedField('phoneType', newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Phone Type" variant="standard" />}
                                    />
                                    <TextField id="phone outlined-basic"
                                        disabled={disabledField}
                                        label='Phone'
                                        variant="standard"
                                        sx={{ width: '20%' }}
                                        value={selectedFields.phone}
                                        onChange={(event) => {
                                            updateSelectedField('phone', event.target.value);
                                        }}
                                    />
                                </Stack>
                                <Stack direction="row" spacing={10} useFlexGap justifyContent="flex-end">
                                    <Tooltip title="This action will suspend the User's ETRO accounts" placement="top">
                                        <Button
                                            disabled={disabledField}
                                            onClick={handleClickOpen}
                                            sx={{ backgroundColor: '#f44336' }}
                                            variant="contained"
                                        >
                                            {employee.suspended ? 'Activate' : 'Suspend'}
                                        </Button>
                                    </Tooltip>

                                    <Stack direction='row' spacing={2}>
                                        <Button
                                            onClick={() => { handleReturnToListUser() }}
                                            color='error'
                                            variant="contained"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={handleUpdateEmployee}
                                            // onClick={updateTitleVcard}
                                            disabled={disabledField}
                                            color='success'
                                            variant="contained"
                                        >
                                            Save
                                        </Button>
                                    </Stack>
                                </Stack>

                            </Stack>
                        </CardContent>
                        {/* {loadingSave && <Loader />} */}
                    </Card>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You are about to suspend {employee.full_name} accounts.
                                Are you sure you want to do this?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog}>Cancel</Button>
                            <Button onClick={suspendUser}>Confirm</Button>

                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openDialogNewDepartment}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-department"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <TextField
                                label="Enter new Department"
                                onChange={(e) => {
                                    setNewDepartment(e.target.value)
                                }}
                            />
                            <DialogActions>
                                <Button
                                    onClick={handleCloseDialog}
                                    variant="outlined"
                                >Cancel</Button>
                                <Button
                                    onClick={() => {
                                        createNewDepartment(newDepartment)
                                    }}
                                    variant="outlined"
                                >Confirm</Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={openChangePhoto}
                        onClose={handleClose}
                        id='add-photo'
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Stack spacing={2}>
                                    {fileName.length <= 0 ? (
                                        <Stack>
                                            <Button variant="contained" component="span" onClick={handleClick}>
                                                Upload File
                                            </Button>
                                        </Stack>
                                    ) : (
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            value={fileName}
                                            variant="standard"
                                            disabled />
                                    )
                                    }
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={(e) => {
                                            setImage(e.target.files[0])
                                            setFileName(e.target.files[0].name)
                                        }}
                                        style={{ display: 'none' }}
                                    />

                                    <Stack spacing={0.5}>
                                        <span><b>Format:</b> JPG or PNG.</span>
                                        <span><b>Size:</b> Between 10 KB and 5 MB.</span>
                                        <span><b>Recommended resolution:</b> 720 px tall, 720 px wide.</span>
                                        <span><b>Minimum resolution:</b> 250 px tall, 250 px wide.</span>
                                        <span><b>Quality:</b> The photo should be in focus and well lit, and have no significant
                                            alterations or excessive use of filters.
                                            In other words, the image should represent reality.</span>
                                    </Stack>
                                </Stack>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleCloseDialog}
                                variant="outlined"
                            >Cancel</Button>
                            {/* <form action={`${process.env.REACT_APP_BASE_URL}/auth/google`}> */}
                            <Button
                                type='submit'
                                onClick={() => {
                                    updateProfilePhoto()
                                }}
                                variant="outlined"
                            >Confirm</Button>

                            {/* </form> */}

                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openDialogNewTitle}
                        onClose={handleClose}
                        maxWidth='xl'
                    >
                        <DialogContent>
                            <TextField
                                label="Enter new Title"
                                onChange={(e) => {
                                    setNewTitle(e.target.value)
                                }}
                            />
                            <DialogActions>
                                <Button
                                    onClick={handleCloseDialog}
                                    variant="outlined"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        createNewTitle(newTitle)
                                    }}
                                    variant="outlined"
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                </>
            )}

        </Container >
    );
}

export default Profile;