import React, { useCallback, useContext, useState } from 'react'
import { Fade, Card, CardContent, FormControl, Stack, TextField, Select, MenuItem, Button } from '@mui/material'
import BackdropLoader from './common/BackdropLoader.jsx';
import { SnackbarContext } from '../context/SnackbarContext'
import { OrgUnitPathsContext } from '../context/OrgUnitPathsContext.js'
import { createOrgUnitPathAPI, createOrgOnGoogleAPI } from '../apis/orgUnitPathAPI'

const OrgUnitPathForm = (props) => {
    const { openAlert } = useContext(SnackbarContext)
    const { fetchOrgUnitPaths } = useContext(OrgUnitPathsContext)
    const { setOrgData, orgData, newOrgPathForms, setNewOrgPathForms, setEditingRowId } = props
    const [openBackdrop, setOpenBackdrop] = useState(false)

    const createOrg = async (data) => {
        setNewOrgPathForms(false)
        try {
            await createOrgUnitPathAPI(data)
            openAlert('Organization Unit Paths created successfully', 'success')
            await createOrgOnGoogle(data)
        } catch (error) {
            openAlert(error.message, 'error')

            console.log(error)
        }
        setEditingRowId(null)
    }

    const createOrgOnGoogle = useCallback(async (data) => {
        try {
            await createOrgOnGoogleAPI(data)

            fetchOrgUnitPaths()
        } catch (error) {
            console.log('error create org', error)
        }
    }, [fetchOrgUnitPaths])

    return (
        <>
            <BackdropLoader openBackdrop={openBackdrop} />
            <Fade in={newOrgPathForms} timeout={200}>
                <Card sx={{ boxShadow: 3, marginBottom: 2 }}>
                    <CardContent>
                        <FormControl fullWidth sx={{ m: 0 }} >
                            <Stack direction="row" spacing={1}>
                                <TextField
                                    sx={{ width: '30%' }}
                                    size="small"
                                    id="outlined-basic"
                                    label="Org Unit Path Name"
                                    variant="outlined"
                                    onChange={(e) => {
                                        setOrgData(prevState => ({
                                            ...prevState,
                                            org_unit_path: e.target.value
                                        }))
                                    }}

                                />
                                <TextField
                                    sx={{ width: '60%' }}
                                    size="small"
                                    id="outlined-basic"
                                    label="Description"
                                    variant="outlined"
                                    onChange={(e) => {
                                        setOrgData(prevState => ({
                                            ...prevState,
                                            description: e.target.value
                                        }))
                                    }}
                                />
                                <Select
                                    sx={{ width: '12%' }}
                                    size="small"
                                    value={orgData.etro_team || ''}
                                    onChange={(e) => {
                                        setOrgData(prevState => ({
                                            ...prevState,
                                            etro_team: e.target.value
                                        }))
                                    }}
                                >
                                    <MenuItem key='2' disabled selected value='ETRO Team?'>ETRO Team?</MenuItem>
                                    <MenuItem key='1' value='Yes'>Yes</MenuItem>
                                    <MenuItem key='0' value='No'>No</MenuItem>
                                </Select>
                                <Button
                                    onClick={() => {
                                        setOpenBackdrop(true)
                                        createOrg(orgData)
                                    }}
                                    variant="outlined"
                                    sx={{ width: '10%' }}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </FormControl>
                    </CardContent>
                </Card>
            </Fade>
        </>
    )
}

export default OrgUnitPathForm