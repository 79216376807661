import React from 'react'
import EmployeesList from '../components/EmployeesList.jsx'

const EtroTeamPage = () => {
    return (
        <React.Fragment>
            <EmployeesList />
        </React.Fragment>
    )
}

export default EtroTeamPage