import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent';
import { Typography, TextField, Avatar, Paper, Button, Stack, Snackbar, Alert } from '@mui/material';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { useState, useCallback } from 'react'

export default function CommentsTimeline(props) {
    const { setComment, comment, comments, setComments, setCheckinGoalData, checkinGoalData, goalSelectedToComment } = props
    const [textfieldHelpText, setTextfieldHelpText] = useState('')
    const [errorTextfield, setErrorTextfield] = useState(false)
    const user = localStorage.getItem('user')
    const [loggedUser] = useState(JSON.parse(user))
    const [openSnackbar, setOpenSnackbar] = React.useState(false)
    const [severytypeSnackbar, setSeverytypeSnackbar] = React.useState('')
    const [messageSnackbar, setMessageSnackbar] = React.useState('')

    const handleClickAddComment = () => {
        if (comment === '') {
            setTextfieldHelpText(`Comment should not be empty`)
            setErrorTextfield(true)
            return
        }
        // updateGoalComments()
        handleAddComment()

    }
    const openAlert = (message, type) => {
        setOpenSnackbar(true)
        setMessageSnackbar(message)
        setSeverytypeSnackbar(type)
    }

    const handleAddComment = useCallback(() => {
        const addComments = [...comments, {
            comment: comment,
            commentDate: new Date().toLocaleDateString('en-ca', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            }),
            name: loggedUser.full_name,
            avatar: loggedUser.photo_url
        }]
        setComments(addComments)
        setCheckinGoalData(prevStates => prevStates.map((goal) => goal.id === goalSelectedToComment.id ? {
            ...goal,
            goal_comments: JSON.stringify(addComments)
        } : goal))
        setComment('')
    })

    const handleClose = () => {
        setOpenSnackbar(false)
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={severytypeSnackbar}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {messageSnackbar}
                </Alert>
            </Snackbar>
            <Timeline position='left' sx={{
                [`& .${timelineContentClasses.root}`]: {
                    flex: 0.2,
                },
            }}
            >
                {comments?.map((item, index) => (

                    <TimelineItem key={index} sx={{ width: '60%' }} >
                        <TimelineOppositeContent
                            sx={{
                                m: 'auto 0',
                            }}
                            align="left"
                            variant="body2"
                            color="text.secondary"

                        >
                            {item.comment}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <Avatar src={item.avatar} />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <Stack alignItems='center'>
                            <TimelineContent sx={{ py: '12px', px: 2, textAlign: 'center' }}>
                                <Typography>{item.name}</Typography>
                                <Typography sx={{ fontSize: '11px' }} color={'text.secondary'} >{item.commentDate}</Typography>
                            </TimelineContent>
                        </Stack>
                    </TimelineItem>
                ))}
            </Timeline >
            <Stack
                m={2}
                direction='row'
                spacing={2}
            >
                <TextField
                    sx={{ marginBottom: 5, width: '80%' }}
                    id="outlined-multiline-static"
                    label="Comment"
                    multiline
                    rows={2}
                    value={comment}
                    variant='outlined'
                    onChange={(e) => {
                        setTextfieldHelpText('')
                        setErrorTextfield(false)
                        setComment(e.target.value)
                    }}
                    helperText={textfieldHelpText}
                    error={errorTextfield}

                />
                <Button
                    variant='outlined'
                    sx={{ width: '20%' }}
                    onClick={handleClickAddComment}
                >
                    Add Comment
                </Button>
            </Stack>
        </>
    );
}
