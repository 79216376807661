import {
    Card, CardContent, Fade, Stack, TextField, FormControl, Button, Container, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow, Fab, Select, MenuItem,
    Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, Snackbar, Alert,
    IconButton
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import globalStyles from '../css/GlobalStyles.module.css'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import api from '../apis/api.js'
import BackdropLoader from './common/BackdropLoader.jsx'
import SearchField from "./common/SearchField.jsx";
import { SnackbarContext } from "../context/SnackbarContext.js";

const WorkLocations = () => {
    const {openAlert} = useContext(SnackbarContext)
    const token = localStorage.getItem('token')
    const [locations, setLocations] = useState([])
    const [allLocations, setAllLocations] = useState([])
    const [search, setSearch] = useState([])
    const [workLocationForms, setWorkLocationForms] = useState(false)
    const [focusRow, setFocusRow] = useState(null)
    const [disabledButtonSave, setDisabledButtonSave] = useState(true)
    const [locationSelectedToDelete, setLocationSelectedToDelete] = useState({
        locationName: '',
        id: ''
    })
    const [locData, setLocData] = useState({
        building_id: null,
        building_name: null,
        province: 'CA',
        description: '',
        floor_name: "['Site Office']",
        postal_code: null,
        // adm_area: 'Adm Area',
        city: 'City',
        address: ''
    })
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [openDeleteLocation, setOpenDeleteLocation] = useState(false)
    const [openSnackbar, setOpenSnackbar] = React.useState(false)
    const [severytypeSnackbar, setSeverytypeSnackbar] = React.useState('')
    const [messageSnackbar, setMessageSnackbar] = React.useState('')
    const [editingRowId, setEditingRowId] = useState('')

    const getWorkLocations = async () => {
        setOpenBackdrop(true)
        try {
            const response = await api.get(`/work-locations`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            if (response.status === 200) {
                const locations = response.data
                locations.sort((a, b) => a.building_name?.localeCompare(b.building_name))
                setLocations(locations)
                setAllLocations(locations)
            }

            setOpenBackdrop(false)

        } catch (error) {
            console.error('Somenthing was wrong for bring work locations')
            console.error(error)
        }

    }

    const createWorkLocation = async (data) => {

        setOpenBackdrop(true)
        const existLocation = locations.filter(loc => loc.building_name === data.building_name)
        if (existLocation.length) {
            openAlert('Work Location already exist', 'error')
            setOpenBackdrop(false)
            return
        }

        clearLocData()

        try {
            const response = await api.post('/work-locations', data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            if (response.data.status === 200) {
                openAlert(response.data.message, 'success')
                getWorkLocations()
                createWorkLocationOnGoogle(data)
            }
            setWorkLocationForms(false)
            setLocData(prevState => ({ ...prevState, city: 'City' }))
            setOpenBackdrop(false)

        } catch (error) {
            console.log('Error on create Work Location', error)
        }
    }

    const createWorkLocationOnGoogle = async (data) => {

        setOpenBackdrop(true)

        try {
            const response = await api.post('/google/work-locations', data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            if (response?.data?.error?.status === 409) {
            } else {
                getWorkLocations()
            }
            setOpenBackdrop(false)

        } catch (error) {
            console.log('Error on create Work Location', error)
        }
    }

    const deleteWorkLocation = async () => {

        setOpenBackdrop(true)
        setOpenDeleteLocation(false)
        const id = locationSelectedToDelete.id
        // const existLocation = locations.filter(loc => loc.id === id)

        try {
            const response = await api.delete(`/work-locations/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            if (response.data.status === 200) {
                openAlert(response.data.message, 'success')
                deleteWorkLocationOnGoogle(id)
                getWorkLocations()
            }

            setOpenBackdrop(false)

        } catch (error) {
            openAlert('Error on delete Work Location', 'error')
            console.log('Error on delete Work Location', error)
        }
    }

    const deleteWorkLocationOnGoogle = async (id) => {
        const location = locations.filter(loc => loc.id === id)

        if (location.length) {
            var locationName = location[0].building_name
        }

        try {
            const response = await api.delete(`/google/work-locations/${locationName}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            console.log('response', response)

        } catch (error) {
            openAlert('Error on delete Work Location', 'error')
            console.log('Error on delete Work Location', error)
        }
    }

    const updateWorkLocation = async (id, data) => {

        setOpenBackdrop(true)

        try {
            const response = await api.put(`/work-locations/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            if (response?.data?.error?.status === 409) {
                console.log('response', response)
            } else {
                openAlert('Work Location was updated successful', 'success')
                console.log('response', response)
            }

            getWorkLocations()
            setEditingRowId(null)
            updateWorkLocationOnGoogle(id, data)
            setTimeout(() => {
            }, 2000);
            setOpenBackdrop(false)

        } catch (error) {
            console.log('Error on update Work Location', error)
        }
    }

    const updateWorkLocationOnGoogle = async (id, data) => {
        const location = locations.filter(loc => loc.id === id)

        if (!location.length) {
            return
        }

        var locationName = location[0].building_name

        setOpenBackdrop(true)

        try {
            const response = await api.put(`/google/work-locations/${locationName}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            if (response?.data?.error?.status === 409) {
            } else {
                getWorkLocations()
            }
            setEditingRowId(null)
            setOpenBackdrop(false)

        } catch (error) {
            console.log('Error on create Work Location', error)
        }
    }

    const searchingLocations = (search) => {
        const searchLocations = allLocations.filter(location => {
            return location.building_name.toLowerCase().includes(search.toLowerCase()) ||
                location.city.toLowerCase().includes(search.toLowerCase()) ||
                location.postal_code.toLowerCase().includes(search.toLowerCase()) ||
                location.address.toLowerCase().includes(search.toLowerCase())
        })
        setLocations(searchLocations)
    }

    useEffect(() => {
        searchingLocations(search)
    }, [search])

    useEffect(() => {
        getWorkLocations()
    }, [])

    const clearLocData = () => {
        setLocData(prevState => ({
            ...prevState,
            building_name: '',
            postal_code: '',
            city: 'City',
            address: ''
        }))
    }

    const disabledButtonSaveFunction = () => {
        if (!locData.building_name || !locData.postal_code || !locData.city || !locData.address) {
            setDisabledButtonSave(true)
        } else {
            setDisabledButtonSave(false)
        }
    }

    useEffect(() => {
        disabledButtonSaveFunction()
    }, [locData])

    return (
        <Container>
            {openBackdrop && (
                <BackdropLoader openBackdrop={open} />
            )}
            <Card className={globalStyles.card}>
                <CardContent>
                    <Stack mb={1} justifyContent='space-between' direction="row" alignItems="center" >
                        <SearchField setSearch={setSearch} />
                        <Button
                            onClick={() => {
                                clearLocData()
                                setEditingRowId(null)
                                disabledButtonSaveFunction()
                                setWorkLocationForms(!workLocationForms)
                            }}
                            variant="text"
                            startIcon={<AddIcon />} >Add New</Button>
                    </Stack>
                    {workLocationForms && (
                        <Fade in={workLocationForms} timeout={200}>
                            <Card sx={{ boxShadow: 3, marginBottom: 2 }}>
                                <CardContent>
                                    <FormControl fullWidth sx={{ m: 0 }} >
                                        <Stack direction="row" spacing={1}>
                                            <TextField
                                                sx={{ width: '30rem' }}
                                                size="small"
                                                id="outlined-basic"
                                                label="Building Name"
                                                variant="outlined"
                                                onChange={(e) => {
                                                    setLocData(prevStates => ({ ...prevStates, building_name: e.target.value }))
                                                    setLocData(prevStates => ({ ...prevStates, building_id: e.target.value.replaceAll(' ', '-') }))
                                                }}
                                            />
                                            <TextField
                                                sx={{ width: '10rem' }}
                                                size="small"
                                                id="outlined-basic"
                                                label="Postal Code"
                                                variant="outlined"
                                                onChange={(e) => setLocData(prevStates => ({ ...prevStates, postal_code: e.target.value }))}
                                            />
                                            {/* <FormControl sx={{ width: '10rem' }} id="outlined-basic" variant="outlined">
                                                <Select
                                                    value={locData.adm_area}
                                                    onChange={(e) => {
                                                        setLocData(prevStates => ({
                                                            ...prevStates,
                                                            adm_area: e.target.value
                                                        }))
                                                    }}
                                                >
                                                    <MenuItem value="Adm Area" disabled>Adm Area</MenuItem>
                                                    <MenuItem value="BC">BC</MenuItem>
                                                </Select>
                                            </FormControl> */}
                                            <FormControl
                                                sx={{ width: '12rem' }}
                                                id="outlined-basic"
                                                size="small"
                                                variant="outlined"
                                            >
                                                <Select
                                                    value={locData.city}
                                                    onChange={(e) => {
                                                        setLocData(prevStates => ({
                                                            ...prevStates,
                                                            city: e.target.value
                                                        }))
                                                    }}
                                                >
                                                    <MenuItem value="City" disabled>City</MenuItem>
                                                    <MenuItem value="Abbotsford">Abbotsford</MenuItem>
                                                    <MenuItem value="Burnaby">Burnaby</MenuItem>
                                                    <MenuItem value="Coquitlam">Coquitlam</MenuItem>
                                                    <MenuItem value="Delta">Delta</MenuItem>
                                                    <MenuItem value="Kamloops">Kamloops</MenuItem>
                                                    <MenuItem value="Kelowna">Kelowna</MenuItem>
                                                    <MenuItem value="Maple Ridge">Maple Ridge</MenuItem>
                                                    <MenuItem value="Nanaimo">Nanaimo</MenuItem>
                                                    <MenuItem value="New Westminster">New Westminster</MenuItem>
                                                    <MenuItem value="Richmond">Richmond</MenuItem>
                                                    <MenuItem value="Surrey">Surrey</MenuItem>
                                                    <MenuItem value="Vancouver">Vancouver</MenuItem>
                                                    <MenuItem value="Vitória">Vitória</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <TextField
                                                    label="Address Lines"
                                                    size="small"
                                                    onChange={(e) => {
                                                        setLocData(prevStates => ({
                                                            ...prevStates,
                                                            address: e.target.value
                                                        }))
                                                    }}
                                                />
                                            </FormControl>
                                            <Button
                                                disabled={disabledButtonSave}
                                                onClick={() => {
                                                    createWorkLocation(locData)
                                                }}
                                                variant="outlined">Save</Button>
                                        </Stack>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Fade>
                    )}
                    <TableContainer sx={{ boxShadow: 3 }} className={globalStyles.tableContainer}>
                        <Table stickyHeader className={globalStyles.tableStyles}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Building Name</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Postal Code</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>City</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Address</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={globalStyles.cursor_pointer}>
                                {locations.map((location) => (
                                    <TableRow
                                        hover
                                        key={location.id}
                                        onMouseEnter={() => setFocusRow(location.id)}
                                        onMouseLeave={() => setFocusRow(null)}
                                        sx={{ height: '10vh' }}
                                    >
                                        <TableCell sx={{ width: '40%' }}>
                                            {editingRowId === location.id ? (
                                                <Fade in={editingRowId === location.id} timeout={200}>
                                                    <TextField
                                                        sx={{ width: 310 }}
                                                        size="small"
                                                        label="Building Name"
                                                        value={locData.building_name}
                                                        onChange={(e) => {
                                                            const updatedLocation = locations.map(item =>
                                                                item.id === location.id ? { ...item, building_name: e.target.value } : item
                                                            )
                                                            setLocations(updatedLocation)
                                                            setLocData(prevState => ({
                                                                ...prevState,
                                                                building_name: e.target.value
                                                            }))
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                updateWorkLocation(location.id, locData);
                                                            }
                                                        }}
                                                        autoFocus
                                                    >

                                                    </TextField>
                                                </Fade>
                                            ) : (
                                                location.building_name
                                            )}

                                        </TableCell>
                                        <TableCell sx={{ width: '20%' }}>
                                            {editingRowId === location.id ? (
                                                <Fade in={editingRowId === location.id} timeout={200}>
                                                    <TextField
                                                        size="small"
                                                        label="Postal Code"
                                                        value={locData.postal_code}
                                                        onChange={(e) => {
                                                            const updatedLocation = locations.map(item =>
                                                                item.id === location.id ? { ...item, postal_code: e.target.value } : item
                                                            )
                                                            setLocations(updatedLocation)
                                                            setLocData(prevState => ({
                                                                ...prevState,
                                                                postal_code: e.target.value
                                                            }))
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                updateWorkLocation(location.id, locData);
                                                            }
                                                        }}
                                                    >

                                                    </TextField>
                                                </Fade>
                                            ) : (
                                                location.postal_code
                                            )}
                                        </TableCell>
                                        <TableCell sx={{ width: '20%' }}>
                                            {editingRowId === location.id ? (
                                                <Fade in={editingRowId === location.id} timeout={200}>
                                                    <FormControl>
                                                        <Select
                                                            value={locData.city}
                                                            size="small"
                                                            onChange={(e) => {
                                                                const updatedLocation = locations.map(item =>
                                                                    item.id === location.id ? { ...item, city: e.target.value } : item
                                                                )
                                                                setLocations(updatedLocation)
                                                                setLocData(prevState => ({
                                                                    ...prevState,
                                                                    city: e.target.value
                                                                }))
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    updateWorkLocation(location.id, locData);
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem value="Abbotsford">Abbotsford</MenuItem>
                                                            <MenuItem value="Burnaby">Burnaby</MenuItem>
                                                            <MenuItem value="Coquitlam">Coquitlam</MenuItem>
                                                            <MenuItem value="Delta">Delta</MenuItem>
                                                            <MenuItem value="Kamloops">Kamloops</MenuItem>
                                                            <MenuItem value="Kelowna">Kelowna</MenuItem>
                                                            <MenuItem value="Maple Ridge">Maple Ridge</MenuItem>
                                                            <MenuItem value="Nanaimo">Nanaimo</MenuItem>
                                                            <MenuItem value="New Westminster">New Westminster</MenuItem>
                                                            <MenuItem value="Richmond">Richmond</MenuItem>
                                                            <MenuItem value="Surrey">Surrey</MenuItem>
                                                            <MenuItem value="Vancouver">Vancouver</MenuItem>
                                                            <MenuItem value="Vitória">Vitória</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Fade>
                                            ) : (
                                                location.city
                                            )}

                                        </TableCell>
                                        <TableCell sx={{ width: '20%' }}>
                                            {editingRowId === location.id ? (
                                                <Fade in={editingRowId === location.id} timeout={200}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        label="Address Lines"
                                                        sx={{ width: '15rem' }}
                                                        value={locData.address}
                                                        onChange={(e) => {
                                                            const updatedLocation = locations.map(item =>
                                                                item.id === location.id ? { ...item, address: e.target.value } : item
                                                            )
                                                            setLocations(updatedLocation)
                                                            setLocData(prevState => ({
                                                                ...prevState,
                                                                address: e.target.value
                                                            }))
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                updateWorkLocation(location.id, locData);
                                                            }
                                                        }}
                                                    >

                                                    </TextField>
                                                </Fade>
                                            ) : (
                                                location.address
                                            )}

                                        </TableCell>
                                        <TableCell sx={{ width: '15%' }}>
                                            {(editingRowId !== location.id) && (
                                                <Stack direction="row" spacing={1}>
                                                    <IconButton
                                                        onClick={(event) => {
                                                            setEditingRowId(location.id)
                                                            setWorkLocationForms(false)
                                                            setLocData({
                                                                building_id: location.building_id,
                                                                building_name: location.building_name,
                                                                province: 'CA',
                                                                description: '',
                                                                floor_name: "['Site Office']",
                                                                postal_code: location.postal_code,
                                                                // adm_area: location.adm_area,
                                                                city: location.city === "" ? "City" : location.city,
                                                                address: location.address
                                                            })
                                                        }}
                                                    >
                                                        <EditIcon

                                                        />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            setOpenDeleteLocation(true)
                                                            setLocationSelectedToDelete({
                                                                locationName: location.building_name,
                                                                id: location.id
                                                            })
                                                        }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Stack>
                                            )}
                                            {editingRowId === location.id && (
                                                <Stack direction='row'>
                                                    <IconButton onClick={() => {
                                                        updateWorkLocation(location.id, locData)
                                                    }}>
                                                        <SaveIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => setEditingRowId(null)}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Stack>

                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            <Dialog
                open={openDeleteLocation}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete {locationSelectedToDelete.locationName}.
                        Are you sure you want to do this?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteLocation(false)}>Cancel</Button>
                    <Button onClick={deleteWorkLocation} >Confirm</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default WorkLocations