import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react'
import { Paper, Box, Typography, IconButton, Tooltip, Snackbar, Alert } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import api from '../apis/api.js';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Delete from '@mui/icons-material/Delete.js';
import BackdropLoader from './common/BackdropLoader.jsx';
import { GoalsContext } from '../context/GoalsContext.js';
import { CheckInContext } from '../context/CheckInContexts.js';

const CheckIns = (props) => {

    const { goals, getGoals } = useContext(GoalsContext)
    const { checkIns, getCheckIns } = useContext(CheckInContext)
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [openLoader, setOpenLoader] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [severytypeSnackbar, setSeverytypeSnackbar] = useState('')
    const [messageSnackbar, setMessageSnackbar] = useState('')
    const [plusIconDisabled, setPlusIconDisabled] = useState(false)

    const getCheckInData = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await api.get('/checkins')
            const data = response.data
            const checkinPlayer = data.filter((checkin) => {
                return checkin.player_id === props.playerId && checkin.status !== 'archived'
            })

            let checkinPlayerFiltered
            checkinPlayerFiltered = checkinPlayer.filter((checkin) => {
                return checkin.check_in_type === 'monthly-review' && new Date(checkin.check_in_date).getUTCFullYear() === props.peopleBuilder?.year
            })

            const checkinData = checkinPlayerFiltered.map(checkin => ({
                id: checkin.id,
                month: checkin.monthly_ref,
                checkinDate: checkin.check_in_date.split('T')[0],
                meeting: checkin.meeting_date ? 'Yes' : 'No',
                goal_comments: checkin.goal_comments,
            }))

            checkinData.sort((a, b) => new Date(b.checkinDate) - new Date(a.checkinDate))

            setRowsData(checkinData);
        } catch (error) {
            console.error('Something went wrong:', error);
        } finally {
            setIsLoading(false);
        }
    }, [props.playerId, props.title])

    const columns = useMemo(() => [
        {
            field: 'month',
            headerName: 'Month',
            headerClassName: 'checkin-header',
            width: 300,
        },
        {
            field: 'checkinDate',
            headerName: 'Check-In Date',
            headerClassName: 'checkin-header',
            width: 400,
        },
        {
            field: 'meeting',
            headerName: '1:1 Meeting?',
            headerClassName: 'checkin-header',
            width: 300,
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Actions',
            headerClassName: 'checkin-header',
            width: 100,
            renderCell: (params) => {
                return (
                    <Tooltip title="Delete Check-In">
                        <IconButton onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(params.id)
                        }}>
                            <Delete />
                        </IconButton>
                    </Tooltip >
                )
            }
        }
    ], [])

    const handleClose = () => {
        setOpenSnackbar(false)
    };

    const handleDelete = useCallback(async (id) => {
        setOpenLoader(true)
        try {
            const response = await api.delete(`/checkins/${id}`);

            if (response.status === 200) {
                setRowsData(prevData => prevData.filter(row => row.id !== id))
                getCheckInData()
                setOpenLoader(false)
                await getGoals()
                hasGoals()
                getCheckIns()

            }

            openAlert('Check-in deleted successfully', 'success')
        } catch (error) {
            console.error('Error deleting check-in:', error)
            openAlert('Error deleting check-in', 'error')
            setOpenLoader(false)
        }
    }, [getCheckInData])

    const openCheckInForm = useCallback((id) => {
        const userGoals = goals.filter(goal => goal.people_builder.player.id === id)
        let userGoalsStatus = new Set()

        if (userGoals.length) {
            userGoals.map(goal => userGoalsStatus.add(goal.goal_status))
        }

        if (userGoalsStatus.has('Draft Goal') || userGoalsStatus.has('Approval') || userGoalsStatus.has('Needs Revision')) {
            openAlert('Goals should be Active', 'error')
            return
        } else if (!userGoalsStatus.size) {
            openAlert('All goals need to be Active', 'error')
            return
        }

        navigate(`/new-check-in/${id}`);
    }, [goals])

    const hasGoals = useCallback(() => {
        const userGoals = goals.filter(goal => goal.people_builder.player.id === props.playerId && goal.goal_status !== 'Active' && goal.goal_status !== 'archived')

        if (userGoals.length) {
            setPlusIconDisabled(true)
        } else {
            setPlusIconDisabled(false)
        }
    }, [goals])

    const openAlert = (message, type) => {
        setOpenSnackbar(true)
        setSeverytypeSnackbar(type)
        setMessageSnackbar(message)
    }

    const openRefCheckInForm = useCallback((id) => {
        navigate(`/check-in/${id}`)
    }, [navigate])

    useEffect(() => {
        getCheckInData()
        hasGoals()
        getCheckIns()
    }, [])

    useEffect(() => {
        hasGoals()
    }, [goals, getGoals])

    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={severytypeSnackbar}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {messageSnackbar}
                </Alert>
            </Snackbar>
            {openLoader && (
                <BackdropLoader openBackdrop={openLoader} />
            )}
            <Paper sx={{ p: 2, m: 2 }}>
                <Typography
                    variant="h6"
                    textAlign='center'
                    gutterBottom
                    component="div"
                >
                    Monthly Reviews
                </Typography>

                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    rowsData.length > 0 ? (
                        <DataGrid
                            rows={rowsData}
                            columns={columns}
                            disableColumnResize={true}
                            onRowClick={(params) => openRefCheckInForm(params.row.id)}
                            rowSelection={false}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 4,
                                        page: 0
                                    },
                                },
                            }}
                            pageSizeOptions={[4]}
                            sx={{
                                '& .MuiDataGrid-cell:focus-within': {
                                    outline: 'none',
                                    backgroundColor: 'none'
                                },
                                '& .MuiDataGrid-cell:hover': {
                                    backgroundColor: 'none',
                                },
                                '& .checkin-header': {
                                    backgroundColor: '#ccc',
                                },
                                '& .MuiDataGrid-row': {
                                    cursor: 'pointer',
                                },
                                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                            }}
                        />
                    ) : (
                        <DataGrid
                            rows={[{ month: 'No Reviews', id: 0 }]}
                            columns={[
                                {
                                    field: 'month',
                                    headerName: 'Month',
                                    headerClassName: 'checkin-header',
                                    width: 300,
                                    align: 'center',
                                    colSpan: 4
                                },
                                {
                                    field: 'checkinDate',
                                    headerName: 'Check-In Date',
                                    headerClassName: 'checkin-header',
                                    width: 400,
                                },
                                {
                                    field: 'meeting',
                                    headerName: '1:1 Meeting?',
                                    headerClassName: 'checkin-header',
                                    width: 300,
                                    flex: 1
                                },
                                {
                                    field: 'actions',
                                    headerName: 'Actions',
                                    headerClassName: 'checkin-header',
                                    width: 100,
                                }]}
                            disableColumnResize={true}
                            paginationModel={{ page: 0, pageSize: 4 }}
                            pageSizeOptions={[4]}
                            disableRowSelectionOnClick={true}
                            sx={{
                                '& .MuiDataGrid-cell:focus-within': {
                                    outline: 'none',
                                    backgroundColor: 'none'
                                },
                                '& .MuiDataGrid-cell:hover': {
                                    backgroundColor: 'none',
                                },
                                '& .checkin-header': {
                                    backgroundColor: '#ccc',
                                },
                                '& .MuiDataGrid-row': {
                                    cursor: 'pointer',
                                },
                                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                            }}
                        />
                    )
                )}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    {plusIconDisabled ? (
                        <Tooltip title="All goals need to be Active">
                            <Box>
                                <IconButton disabled onClick={() => openCheckInForm(props.playerId)}>
                                    <AddCircleOutlineIcon sx={{ cursor: 'pointer' }} />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Add Check-In">
                            <IconButton onClick={() => openCheckInForm(props.playerId)}>
                                <AddCircleOutlineIcon sx={{ cursor: 'pointer' }} />
                            </IconButton>
                        </Tooltip>
                    )}

                </Box>
            </Paper>
        </React.Fragment >
    )
}

export default React.memo(CheckIns)