import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom'
import {
    IconButton, Table, TableBody, TableCell,
    TableHead, TableRow, Typography, Paper, Container, Stack,
    Card, Autocomplete, TextField, Fade, CardContent

} from '@mui/material'
import api from '../apis/api.js';
import BackdropLoader from './common/BackdropLoader.jsx'
import InputSearch from './InputSearch.jsx'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PeopleBuilderRow from './PeopleBuilderRow.jsx'
import { PeopleBuilderContext } from '../context/PeopleBuilderContext.js'
import { CheckInContext } from '../context/CheckInContexts.js';

const PeopleBuilder = () => {
    const { userId } = useParams()
    const [scrollPlayerId, setScrollPlayerId] = useState([])
    const [goals, setGoals] = useState([])
    const [title, setTitle] = useState([])
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [pbStatus, setPbStatus] = useState('')
    const [listName, setListName] = useState([])
    const [filteredPeopleBuilder, setFilteredPeopleBuilder] = useState([]);
    const [playerNameToFilter, setPlayerNameToFilter] = useState('')
    const [yearToFilter, setYearToFilter] = useState('')
    const [statusToFilter, setStatusToFilter] = useState([])
    const [status, setStatus] = useState([])
    const [openFilter, setOpenFilter] = useState(false)
    const [years, setYears] = useState([])
    const location = useLocation()
    const { checkIns, getCheckIns } = useContext(CheckInContext)
    const { allPeopleBuilder, filterType } = useContext(PeopleBuilderContext)
    const cardRef = useRef(0);
    const rowRefs = useRef({});
    const ref = useRef(null)
    const statusOptions = [
        { label: 'Draft Goal' },
        { label: 'Approval' },
        { label: 'Active' },
        { label: 'Completed' },
        { label: 'No Goals' }
    ]

    const handleFilter = useCallback(() => {
        let filtered = []
        let userStatus = new Set()

        filtered = allPeopleBuilder.filter(user => {
            let goalsFiltered = goals.filter(goal => goal.people_builder_id === user.id)
            goalsFiltered.map((goal) => userStatus.add(goal.goal_status))
            let byName = user.player.full_name.toLowerCase().includes(playerNameToFilter.toLowerCase()) || playerNameToFilter === ''
            let byYear = user?.year == yearToFilter || yearToFilter === ''
            let byStatus = statusToFilter?.some(status => Array.from(userStatus)?.includes(status)) || !statusToFilter.length
            userStatus.clear()

            return byName && byYear && byStatus
        })

        setFilteredPeopleBuilder(filtered)
    }, [playerNameToFilter, yearToFilter, statusToFilter])

    const peopleBuilders = async () => {
        const userLogged = JSON.parse(localStorage.getItem('user'))

        if (location.pathname.includes('personal')) {
            setTitle('Personal People Builder')
            const personalPeopleBuilder = allPeopleBuilder.filter(user => user.player_id === userId)
            setFilteredPeopleBuilder(personalPeopleBuilder)
        } else if (location.pathname.includes('team')) {
            setTitle('Team People Builder')
            const teamPeopleBuilder = allPeopleBuilder.filter(user => user.coach_id === userId || user.player.coach === userLogged.full_name)
            teamPeopleBuilder.sort((a, b) => a.player.full_name.localeCompare(b.player.full_name))
            teamPeopleBuilder.sort((a, b) => a.year - b.year)
            setFilteredPeopleBuilder(teamPeopleBuilder)
        } else {
            setOpenBackdrop(true)
            setTitle('Company People Builder')
            allPeopleBuilder.sort((a, b) => a.player.full_name.localeCompare(b.player.full_name))
            allPeopleBuilder.sort((a, b) => a.year - b.year)

            setFilteredPeopleBuilder(allPeopleBuilder)
        }

        if (filterType === 'personal') {
            setTitle('Personal People Builder')
        } else if (filterType === 'team') {
            setTitle('Team People Builder')
        } else if (filterType === 'all') {
            setTitle('Company People Builder')
        }

        setTimeout(() => {
            setOpenBackdrop(false)
        }, 1000);
    }

    const getGoals = useCallback(async () => {
        try {
            const response = await api.get(`/goals`)
            const goals = response.data.filter(goal => goal.goal_status !== 'archived')
            setGoals(goals)

        } catch (error) {
            console.log(error)
        }
    })

    const handleScroll = (playerId) => {
        const rowElement = rowRefs.current[playerId];

        if (rowElement) {
            rowElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setTimeout(() => {
                const cardElement = cardRef.current;
                cardElement.scrollBy({ top: - 70, behavior: 'smooth' });

            }, 1000);
        }
    };

    useEffect(() => {
        setScrollPlayerId(localStorage.getItem('playerIdClickedPB'))
    }, [scrollPlayerId])

    useEffect(() => {
        setTimeout(() => {
            scrollingPage()
        }, 1000);

    }, [scrollPlayerId])

    const scrollingPage = useCallback(() => {
        if (!scrollPlayerId) {
            return;
        }
        const rowElement = rowRefs.current[scrollPlayerId];
        if (rowElement) {
            rowElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            
            setTimeout(() => {
                const cardElement = cardRef.current;
                cardElement.scrollBy({ top: - 70, behavior: 'smooth' });

            }, 2000);
        }
    }, [scrollPlayerId])

    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.removeItem('playerIdClickedPB');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        handleFilter()
    }, [playerNameToFilter, yearToFilter, statusToFilter])

    useEffect(() => {
        peopleBuilders()
        getGoals()
    }, [filterType])

    useEffect(() => {
        const listNameCheck = new Set()
        const newListName = []

        allPeopleBuilder.forEach((user) => {
            if (user.player.full_name && !listNameCheck.has(user.player.full_name)) {
                listNameCheck.add(user.player.full_name);
                newListName.push({ label: user.player.full_name });
            }
        });

        setListName(newListName);

    }, [allPeopleBuilder])

    useEffect(() => {
        peopleBuilders()
        const years = []
        const getYears = []
        allPeopleBuilder.map((user, index) => {
            if (!years.includes(user.year.toString())) {
                getYears.push({ label: user.year.toString(), id: index })
            }
            years.push(user.year.toString())
        })
        setYears(getYears)
    }, [allPeopleBuilder])

    useEffect(() => {
        getCheckIns()
    }, [])

    return (
        <Container maxWidth='xl' >
            <BackdropLoader openBackdrop={openBackdrop} />
            <Card ref={cardRef} sx={{ mt: 4, maxHeight: 850, overflowY: 'auto' }}>
                <CardContent >
                    <Typography variant='h5' align='center'>{title}</Typography>
                    {openFilter && title !== 'Personal People Builder' && (
                        <Fade in={openFilter} timeout={500}>
                            <Paper elevation={2} sx={{ mt: 4, p: 2 }}>
                                <Stack direction='row' gap={2}>
                                    <InputSearch
                                        sizeProp={300}
                                        list={listName}
                                        value={playerNameToFilter?.label}
                                        onChange={(e, name) => {
                                            name ? setPlayerNameToFilter(name?.label) : setPlayerNameToFilter('')
                                            handleFilter()
                                        }}
                                        label='Player'
                                    />
                                    <InputSearch
                                        list={years}
                                        sizeProp={200}
                                        value={yearToFilter || { label: new Date().getFullYear().toString() }}
                                        onChange={(e, value) => {
                                            value ? setYearToFilter(value?.label) : setYearToFilter('')
                                            handleFilter()
                                        }}
                                        label='Year'
                                    />
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={statusOptions}
                                        onChange={(e, value) => {
                                            setStatus(value)
                                            value ? setStatusToFilter(value.map(status => status.label)) : setStatusToFilter([])
                                            handleFilter()
                                        }}
                                        sx={{ width: 600 }}
                                        size='small'
                                        value={status}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Status"
                                            />
                                        )}
                                    />
                                </Stack>
                            </Paper>
                        </Fade>
                    )}
                    {/* <TableContainer className={styles.tableContainer} sx={{ mt: 2 }}> */}
                    <Table stickyHeader aria-label="collapsible table" sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow >
                                <TableCell sx={{ backgroundColor: '#ccc' }}>
                                    {filterType !== 'personal' && (
                                        <IconButton onClick={() => setOpenFilter(!openFilter)}>
                                            <FilterAltIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                                <TableCell sx={{ backgroundColor: '#ccc' }}>Player</TableCell>
                                <TableCell sx={{ backgroundColor: '#ccc' }}>Coach</TableCell>
                                <TableCell sx={{ backgroundColor: '#ccc' }}>Year</TableCell>
                                <TableCell sx={{ backgroundColor: '#ccc' }}>Status</TableCell>
                                <TableCell sx={{ backgroundColor: '#ccc' }}>Qty of Goals</TableCell>
                                <TableCell sx={{ backgroundColor: '#ccc' }}>Creation</TableCell>
                                <TableCell sx={{ backgroundColor: '#ccc' }}>Last Check-In</TableCell>
                                {/* <TableCell sx={{ backgroundColor: '#ccc' }}>Actions</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredPeopleBuilder.length ? (
                                filteredPeopleBuilder?.map((user) => (
                                    <PeopleBuilderRow
                                        key={user.id}
                                        ref={(el) => (rowRefs.current[user.player.id + user.year] = el)}
                                        pbStatus={pbStatus}
                                        setPbStatus={setPbStatus}
                                        playerId={user.player_id}
                                        getGoals={getGoals}
                                        goals={goals}
                                        peopleBuilder={user}
                                        checkIns={checkIns}
                                        handleScroll={() => handleScroll(user.player.id + user.year)}

                                    />
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={8}>
                                        <Typography variant="h5" color="initial" align='center'>No People Builder</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {/* </TableContainer> */}
                </CardContent>
            </Card>
        </Container>
    );
}

export default PeopleBuilder