import React, { useState } from 'react'
import { createContext } from 'react'
import api from '../apis/api'

export const GoalsContext = createContext()

export const GoalsProvider = ({ children }) => {

    const [goals, setGoals] = useState([])

    const getGoals = async () => {

        try {
            const response = await api.get('/goals')
            setGoals(response.data)
        } catch (error) {
            console.log('goals context', error)
        }
    }

    return (
        <GoalsContext.Provider value={{ getGoals, goals }} >
            {children}
        </GoalsContext.Provider>
    )

}
