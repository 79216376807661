import React, { useContext, useEffect, useState, useCallback } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { FormGroup, FormControlLabel, Checkbox, Stack, Card, CardContent, Container, Avatar } from '@mui/material';
import { EmployeesContext } from '../context/UsersContext.js';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchField from "./common/SearchField.jsx";

const ListUsersWithDataGrid = () => {
    const { employees, fetchUsers } = useContext(EmployeesContext)
    const [rows, setRows] = useState([])
    const [search, setSearch] = useState('')
    const [employeesFiltered, setEmployeesFiltered] = useState([])
    const [checked, setChecked] = useState(false)
    const location = useLocation()
    const currentPath = location.pathname
    const navigate = useNavigate()
    const [pageSize, setPageSize] = useState(11);

    const usersDataFixed = () => {
        let usersEtroTeam

        if (search) {
            usersEtroTeam = employees.filter(employee => (
                (
                    employee?.work_location?.building_name?.toLowerCase().includes(search.toLowerCase()) ||
                    employee.full_name.toLowerCase().includes(search.toLowerCase()) ||
                    employee?.coach.toLowerCase().includes(search.toLowerCase()) ||
                    employee?.title?.title?.toLowerCase().includes(search.toLowerCase()) ||
                    employee?.department?.department?.toLowerCase().includes(search.toLowerCase())
                ) &&
                employee?.etro_team !== checked
            ))

        } else {
            usersEtroTeam = employees.filter(employee => employee.etro_team !== checked)
        }

        const users = usersEtroTeam.map(employee => ({
            id: employee.id,
            is_admin: employee?.is_delegated_admin,
            full_name: employee?.full_name ?? '',
            title: employee?.title?.title ?? '',
            department: employee?.department?.department ?? '',
            coach: employee?.coach ?? '',
            work_location: employee?.work_location?.building_name ?? '',
            phone: employee?.phone ?? '',
            photo_url: employee?.photo_url ?? ''
        }))
        setRows(users)
    }

    useEffect(() => {
        usersDataFixed()
    }, [search, checked, employees])

    useEffect(() => {

        const results = search.length > 0
            ? employees.filter(employee => (employee.full_name.toLowerCase().includes(search.toLowerCase()) || employee.coach.toLowerCase().includes(search.toLowerCase())) && employee.etro_team !== checked)
            : employees.filter(employee => employee.etro_team !== checked)

        setEmployeesFiltered(results)
    }, [checked, search, employees])

    const handleUserToProfile = useCallback((user) => {

        if (currentPath === '/') {
            navigate(`employee/profile/${user.id}`)
        } else {
            navigate(`profile/${user.id}`)
        }
    })

    const columns = [
        {
            field: 'full_name',
            headerName: 'Name',
            width: 250,
            headerClassName: 'users-header',
            renderCell: (params) => {

                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar src={params?.row.photo_url} sx={{ width: 40, height: 40, marginRight: 2 }} />
                        {params.value}
                        {params.row.is_admin && (
                            <img style={{ alignContent: 'center', width: '20px', marginLeft: '10px' }} src='https://iili.io/Jazab4a.png' />
                        )}
                    </div>
                )
            }
        },
        {
            field: 'title',
            headerName: 'Title',
            width: 250,
            headerClassName: 'users-header',
        },
        {
            field: 'department',
            headerName: 'Department',
            width: 250,
            headerClassName: 'users-header',
        },
        {
            field: 'coach',
            headerName: 'Coach',
            description: 'This column has a value getter and is not sortable.',
            width: 250,
            headerClassName: 'users-header',
        },
        {
            field: 'work_location',
            headerName: 'Work Location',
            width: 250,
            headerClassName: 'users-header',
        },
        {
            field: 'phone',
            headerName: 'Contact Phone',
            width: 204,
            sortable: false,
            headerClassName: 'users-header',
        }
    ]

    useEffect(() => {
        fetchUsers()
        const handleResize = () => {
            const height = window.innerHeight;
            const calculatedPageSize = Math.floor((height - 200) / 70);
            setPageSize(calculatedPageSize);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Container maxWidth="xl" sx={{ mt: 4 }}>
            <Card>
                <CardContent>
                    <Stack direction="row" spacing={2}>
                        <SearchField setSearch={setSearch} />
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Not ETRO Team"
                                onChange={(event) => {
                                    setChecked(event.target.checked)
                                }} />
                        </FormGroup>

                    </Stack>
                    <DataGrid
                        disableColumnResize
                        rows={rows}
                        columns={columns}
                        onRowClick={(e) => handleUserToProfile(e)}
                        disableRowSelectionOnClick
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: pageSize,
                                },
                            },
                        }}
                        pageSizeOptions={[pageSize]}
                        sx={{
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:hover': {
                                backgroundColor: 'none',
                            },
                            '& .users-header': {
                                backgroundColor: '#ccc',
                            },
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                        }}
                    />
                </CardContent>
            </Card>
        </Container>
    )
}

export default ListUsersWithDataGrid