import api from "./api"

export const deleteGoalAPI = async (id) => {

    try {
        const response = await api.delete(`/goals/${id}`)
        return response.data
    } catch (error) {
        console.log(error)
        return error
    }
}

export const getGoalsAPI = async () => {

    try {
        const response = await api.get(`/goals/`)
        return response.data
    } catch (error) {
        console.log(error)
        return error
    }
}