import { React, useCallback, useEffect, useState } from 'react';
import { Chip, Stack, Tooltip, Card, CardContent, Typography } from '@mui/material';
import api from '../apis/api.js';
import styles from '../css/Profile-page.module.css'

function Strengths(props) {
    const [employeeStrength, setEmployeeStrength] = useState([])

    const getUserStrengths = useCallback(async () => {

        try {
            const response = await api.get(`/user/strengths`)
            const userStrengths = response.data
            const strengthFiltered = userStrengths.filter(employee => employee.user_id == props.employeeId)
            setEmployeeStrength(strengthFiltered)
        } catch (error) {
            console.error('Error:', error);
        }
    })

    useEffect(() => {
        getUserStrengths()
    }, [props.employeeId])

    return (
        <div>
            {
                employeeStrength.map((strength, index) => (
                    <Card key={1} sx={{ alignItems: 'center' }}>
                        <Typography sx={{ 'textAlign': 'center' }} >CliftonStrengths®</Typography>
                        <CardContent>
                            <Stack justifyContent='space-between' direction="row" spacing={2}>
                                <Tooltip title={strength.description_1} placement="top">
                                    <Chip sx={{ backgroundColor: strength.color_1, color: 'white' }} label={strength.strength_1} />
                                </Tooltip>
                                <Tooltip title={strength.description_2} placement="top">
                                    <Chip sx={{ backgroundColor: strength.color_2, color: 'white' }} label={strength.strength_2} />
                                </Tooltip>
                                <Tooltip title={strength.description_3} placement="top">
                                    <Chip sx={{ backgroundColor: strength.color_3, color: 'white' }} label={strength.strength_3} />
                                </Tooltip>
                                <Tooltip title={strength.description_4} placement="top">
                                    <Chip sx={{ backgroundColor: strength.color_4, color: 'white' }} label={strength.strength_4} />
                                </Tooltip>
                                <Tooltip title={strength.description_5} placement="top">
                                    <Chip sx={{ backgroundColor: strength.color_5, color: 'white' }} label={strength.strength_5} />
                                </Tooltip>
                            </Stack>
                        </CardContent>
                    </Card>
                ))
            }
        </div >
    );
}

export default Strengths