import React, { useCallback, useState, useMemo, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { Paper, Box, Typography, Alert, Snackbar, CircularProgress, Button } from '@mui/material'
import BackdropLoader from './common/BackdropLoader.jsx';
import { DataGrid } from '@mui/x-data-grid';
import api from '../apis/api.js'
import { GoalsContext } from '../context/GoalsContext.js';

const RequiredCheckIn = (props) => {
    const { playerId } = props
    const { getGoals, goals } = useContext(GoalsContext)
    const [rowsData, setRowsData] = React.useState([
        {
            id: 1,
            month: 'Mid-Year Review',
            checkinDate: '',
            meeting: 'No',
        },
        {
            id: 2,
            month: 'End-Year Review',
            checkinDate: '',
            meeting: 'No',
        }
    ]);
    const [isLoading, setIsLoading] = React.useState(false)
    const navigate = useNavigate();
    const [openLoader] = React.useState(false)
    const [openSnackbar, setOpenSnackbar] = React.useState(false)
    const [severytypeSnackbar, setSeverytypeSnackbar] = React.useState('')
    const [messageSnackbar, setMessageSnackbar] = React.useState('')
    const [pbSettings, setPbSettings] = useState('')
    const [requiredCheckins, setRequiredCheckins] = useState([])

    useEffect(() => {
        getGoals()
        const fetchSettings = async () => {
            try {
                const response = await api.get('/people-builder-settings')
                const settings = response.data.find(setting => setting.id === props.peopleBuilder.pb_settings_id)
                setPbSettings(settings)
            } catch (error) {
                console.log(error)
            }
        }
        fetchSettings()
        getRequiredCheckins()
    }, [])

    const getRequiredCheckins = async () => {

        try {
            const response = await api.get('/checkins')
            const requiredCheckins = response.data.filter(checkin => {
                return checkin.check_in_type !== "monthly-review" &&
                    checkin.status !== 'archived' && new Date(checkin.check_in_date).getUTCFullYear() === new Date(pbSettings.start_date).getUTCFullYear()
            })

            setRequiredCheckins(requiredCheckins)
        } catch (error) {
            console.log(error)
        }

    }
    const months = useMemo(() => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dec'])

    useEffect(() => {
        const updateCheckinDates = () => {
            setIsLoading(true)
            const midYearMonth = new Date(pbSettings?.mid_year_checkin).getUTCMonth()
            const endYearMonth = new Date(pbSettings?.end_year_checkin).getUTCMonth()
            const currentMonth = new Date().getUTCMonth()
            let newRowsDate = []
            const requiredCheckinPlayer = requiredCheckins.filter(checkin => checkin.player_id === playerId && new Date(checkin.check_in_date).getUTCFullYear() === pbSettings.year)
            const reqMidCheckin = requiredCheckinPlayer.filter(checkin => {
                return checkin.check_in_type === "mid-year-review" && new Date(checkin.check_in_date).getUTCFullYear() === pbSettings.year
            })

            if (reqMidCheckin.length) {
                newRowsDate.push({
                    checkinDate: reqMidCheckin[0].check_in_date?.split('T')[0] ?? '',
                    meeting: reqMidCheckin[0].meeting_date ? 'Yes' : 'No',
                    id: reqMidCheckin[0].id,
                    month: 'Mid-Year Review'
                })
            } else {
                if (midYearMonth < currentMonth) {
                    newRowsDate.push({
                        checkinDate: `Review is Due`,
                        meeting: 'No',
                        id: 1,
                        month: 'Mid-Year Review'
                    })
                } else if (midYearMonth > currentMonth) {
                    newRowsDate.push({
                        checkinDate: `Planned for ${months[midYearMonth]}`,
                        meeting: 'No',
                        id: 1,
                        month: 'Mid-Year Review'
                    })
                } else {
                    newRowsDate.push({
                        checkinDate: 'Review is Open',
                        meeting: 'No',
                        id: 1,
                        month: 'Mid-Year Review'
                    })
                }
            }

            const reqEndCheckin = requiredCheckinPlayer.filter(checkin => {
                return checkin.check_in_type === "end-year-review"
            })

            if (reqEndCheckin.length) {
                newRowsDate.push({
                    checkinDate: reqEndCheckin[0].check_in_date?.split('T')[0] ?? '',
                    meeting: reqEndCheckin[0].meeting ? 'Yes' : 'No',
                    id: 2,
                    month: 'Mid-Year Review'
                })
            } else {

                if (endYearMonth < currentMonth) {
                    newRowsDate.push({
                        checkinDate: `Review is Due`,
                        meeting: 'No',
                        id: 2,
                        month: 'End-Year Review'
                    })
                } else if (endYearMonth > currentMonth) {
                    newRowsDate.push({
                        checkinDate: `Planned for ${months[endYearMonth]}`,
                        meeting: 'No',
                        id: 2,
                        month: 'End-Year Review'
                    })
                } else {
                    newRowsDate.push({
                        checkinDate: 'Review is Open',
                        meeting: 'No',
                        id: 2,
                        month: 'End-Year Review'
                    })
                }

            }

            setRowsData(newRowsDate)
            setIsLoading(false)

        }

        updateCheckinDates()
    }, [pbSettings, requiredCheckins]);

    const columns = useMemo(() => [
        {
            field: 'month',
            headerName: 'Review',
            headerClassName: 'checkin-header',
            width: 300,
            renderCell: (params) => (
                <span style={
                    ((t) => {
                        if (params.row.checkinDate === 'Review is Due') {
                            return { fontWeight: 'bold', color: 'red' };
                        } else if (params.row.checkinDate === '') {
                            return { color: 'black' };
                        } else if (params.row.checkinDate === 'Review is Open') {
                            return { color: 'black' };
                        }
                    })()
                }>
                    {params.row.month}
                </span>
            ),
        },
        {
            field: 'checkinDate',
            headerName: 'Check-In Date',
            headerClassName: 'checkin-header',
            width: 400,
            renderCell: (params) => (

                params.row.checkinDate === 'Review is Open' ? (
                    <Button
                        color='success'
                        variant='outlined'
                        sx={{ textTransform: 'math-auto' }}
                    >
                        {params.row.checkinDate}
                    </Button>
                ) : (
                    params.row.checkinDate === 'Review is Due' ? (
                        <Button
                            color='error'
                            variant='outlined'
                            sx={{ textTransform: 'math-auto' }}
                        >
                            {params.row.checkinDate}
                        </Button>
                    ) : (
                        <Typography
                            variant='span'
                        >
                            {params.row.checkinDate}
                        </Typography>

                    )
                )
            ),
        },
        {
            field: 'meeting',
            headerName: '1:1 Meeting?',
            headerClassName: 'checkin-header',
            width: 300,
            flex: 1
        }
    ], [])

    const handleClose = () => {
        setOpenSnackbar(false)
    };

    const openAlert = (message, type) => {
        setOpenSnackbar(true)
        setSeverytypeSnackbar(type)
        setMessageSnackbar(message)
    }

    const openRefCheckInForm = useCallback((params) => {
        const userGoals = goals.filter(goal => goal.people_builder.player.id === playerId && goal.goal_status !== 'archived')

        if (!userGoals.length) {
            openAlert('All goals need to be Active', 'error')
            return
        }

        if (params.row.checkinDate === 'Review is Open' || params.row.checkinDate === 'Review is Due') {

            if (params.row.month === 'Mid-Year Review') {
                navigate(`/new-mid-check-in/${playerId}`)
            } else {
                navigate(`/new-end-check-in/${playerId}`)
            }
        } else if (params.row.checkinDate.includes('Planned')) {
            openAlert(`${params.row.month} is not open`, 'error')
        } else if (params.row.month.includes('Mid') && params.row.checkinDate) {
            navigate(`/mid-year-check-in/${params.row.id}`)
        } else {
            navigate(`/end-year-check-in/${params.row.id}`)
        }

    }, [navigate, goals])

    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={severytypeSnackbar}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {messageSnackbar}
                </Alert>
            </Snackbar>
            {openLoader && (
                <BackdropLoader openBackdrop={openLoader} />
            )}
            <Paper sx={{ p: 2, m: 2 }}>
                <Typography
                    variant="h6"
                    textAlign='center'
                    gutterBottom
                    component="div"
                >
                    Required Reviews
                </Typography>

                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <DataGrid
                        rows={rowsData}
                        disableColumnResize={true}
                        disableRowSelectionOnClick
                        onRowClick={(params) => {
                            openRefCheckInForm(params)
                        }}
                        columns={columns}
                        rowSelection={false}
                        paginationModel={{ page: 0, pageSize: 12 }}
                        pageSizeOptions={[12]}
                        sx={{
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                                backgroundColor: 'none'
                            },
                            '& .MuiDataGrid-cell:hover': {
                                backgroundColor: 'none',
                            },
                            '& .checkin-header': {
                                backgroundColor: '#ccc',
                            },
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                        }}
                    />
                )}
            </Paper>
        </React.Fragment >
    )
}

export default RequiredCheckIn