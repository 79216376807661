import {
    Card, Fade, FormControl, TextField, CardContent, Container, Table,
    TableBody, TableCell, TableContainer, Button, Stack, TableHead, TableRow,
    Fab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Snackbar, Alert, IconButton
} from "@mui/material";
import React, { useCallback, useEffect, useState, useContext } from "react";
import globalStyles from '../css/GlobalStyles.module.css'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import BackdropLoader from './common/BackdropLoader.jsx'
import api from '../apis/api.js'
import SearchField from "./common/SearchField.jsx";
import { SnackbarContext } from "../context/SnackbarContext.js";

const Departments = () => {
    const { openAlert } = useContext(SnackbarContext)
    const [departments, setDepartments] = useState([])
    const [allDepartments, setAllDepartments] = useState([])
    const [search, setSearch] = useState('')
    const token = localStorage.getItem('token')
    const [openBackdrop, setOpenBackdrop] = useState(true)
    const [department, setDepartment] = useState([])
    const [departmentForms, setDepartmentForms] = useState(false)
    const [focusedRow, setFocusedRow] = useState(null);
    const [editingRowId, setEditingRowId] = useState(null);
    const [tempTextField, setTempTextField] = useState('')
    const [iconsVisible, setIconsVisible] = useState(false)
    const [openDeleteDepartment, setOpenDeleteDepartment] = useState(false)
    const [departmentToDelete, setDepartmentToDelete] = useState({
        department: '',
        id: ''
    })

    const getDepartments = useCallback(async () => {
        try {

            api.get(`/departments`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(async res => {
                if (res.status === 200) {
                    var departments = res.data
                    departments.sort((a, b) => a.department.localeCompare(b.department))
                    setDepartments(departments)
                    setAllDepartments(departments)
                } else {
                    setDepartments([])
                }
                setOpenBackdrop(false)
            }).catch((error) => {
                setOpenBackdrop(false)
                console.log(error)
            })

        } catch (e) {
            setOpenBackdrop(false)
            console.log(e)
        }
    })

    const saveNewDepartment = async (newDepartment) => {

        setOpenBackdrop(true)

        try {
            api.post(`/departments`, { department: newDepartment }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(async res => {
                if (res.status === 200) {
                    openAlert('Department created sucessful', 'success')
                    setOpenBackdrop(false)
                    getDepartments()
                }
            })
        } catch (error) {
            openAlert(error.message, 'error')
            console.log(error)
        }

        setEditingRowId(null); // Sair do modo de edição
    };

    const deleteDepartment = useCallback(async () => {
        setOpenDeleteDepartment(false)
        setOpenBackdrop(true)
        const id = departmentToDelete.id

        try {
            api.delete(`/departments/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(async res => {
                openAlert(res.data.message, res.data.status === 200 ? 'success' : 'error')
                setOpenBackdrop(false)
                getDepartments()

            })
        } catch (error) {
            openAlert(error.message, 'error')
            console.log(error)
        }

        setEditingRowId(null); // Sair do modo de edição
    })

    const updateDepartment = async (id, newDepartment) => {
        setOpenBackdrop(true)
        try {
            api.put(`/departments/${id}`, { department: newDepartment }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(async res => {
                if (res.status === 200) {
                    openAlert('Department updated sucessful', 'success')
                    setOpenBackdrop(false)
                    setDepartment(newDepartment)
                }
            })
        } catch (error) {
            openAlert(error.message, 'error')
            console.log(error)
        }

        setEditingRowId(null); // Sair do modo de edição
    };

    const searchingDepartments = (search) => {
        var filteredDepartments = allDepartments.filter(department => {
            return department.department.toLowerCase().includes(search.toLowerCase())
        })
        setDepartments(filteredDepartments)
    }

    useEffect(() => {
        searchingDepartments(search)
    }, [search])

    useEffect(() => {
        getDepartments()
    }, [department])

    const handleClose = () => {
        setOpenSnackbar(false)
    }

    return (
        <Container>
            <Card className={globalStyles.card}>
                {/* {loader && <Loader />} */}
                {openBackdrop && (
                    <BackdropLoader openBackdrop={open} />
                )}
                <CardContent>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent='space-between'>
                        <SearchField setSearch={setSearch} />
                        <Button
                            onClick={() => {
                                setEditingRowId(null)
                                setDepartmentForms(!departmentForms)
                            }}
                            variant="text"
                            startIcon={<AddIcon />}
                        >Add New</Button>
                    </Stack>
                    {departmentForms && (
                        <Fade in={departmentForms} timeout={500}>
                            <Card sx={{ boxShadow: 3, marginBottom: 2 }}>
                                <CardContent>
                                    <FormControl fullWidth sx={{ m: 0 }} >
                                        <Stack direction="row" spacing={1}>
                                            <TextField
                                                sx={{ width: '30%' }}
                                                size='small'
                                                id="outlined-basic"
                                                label="Department Name"
                                                variant="outlined"
                                                onChange={(e) => setDepartment(e.target.value)}
                                            />
                                            <Button
                                                onClick={() => {
                                                    setDepartmentForms(false)
                                                    saveNewDepartment(department)
                                                }}
                                                variant="outlined"
                                                sx={{ width: '10%' }}
                                            >Save</Button>
                                        </Stack>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Fade>
                    )}
                    <TableContainer sx={{ boxShadow: 3 }} className={globalStyles.tableContainer}>
                        <Table stickyHeader className={globalStyles.tableStyles}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Department</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {departments.map(department => (
                                    <TableRow
                                        hover
                                        sx={{ height: 70 }}
                                        className={globalStyles.cursor_pointer}
                                        key={department.id}
                                    >
                                        <TableCell sx={{ width: 500 }}>
                                            {editingRowId === department.id ? (
                                                <TextField
                                                    sx={{ width: '60%' }}
                                                    size='small'
                                                    value={tempTextField}
                                                    onChange={(e) => setTempTextField(e.target.value)}
                                                    // onBlur={() => updatedepartments(departments.id, tempTextField)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            updateDepartments(department.id, tempTextField);
                                                        }
                                                    }}
                                                    autoFocus
                                                />
                                            ) : (
                                                department.department
                                            )}
                                        </TableCell>
                                        <TableCell >
                                            {(editingRowId !== department.id) && (
                                                <Stack visibility={iconsVisible} direction='row' spacing={1}>
                                                    <IconButton
                                                        onClick={(event) => {
                                                            setDepartmentForms(false)
                                                            setEditingRowId(department.id);
                                                            setTempTextField(department.department);
                                                        }}
                                                        size="small" >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            setOpenDeleteDepartment(true)
                                                            setDepartmentToDelete({
                                                                department: department.department,
                                                                id: department.id
                                                            })
                                                        }}
                                                        size="small"
                                                        color="teal">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Stack>
                                            )}
                                            {editingRowId === department.id && (
                                                <Stack direction='row'>
                                                    <IconButton
                                                        onClick={() => {
                                                            updateDepartment(department.id, tempTextField)
                                                        }}
                                                        size="small"
                                                        color="teal">
                                                        <SaveIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => setEditingRowId(null)}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Stack>
                                            )}
                                        </TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            <Dialog
                open={openDeleteDepartment}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete {departmentToDelete.department}.
                        Are you sure you want to do this?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDepartment(false)}>Cancel</Button>
                    <Button onClick={deleteDepartment} >Confirm</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default Departments