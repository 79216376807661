import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import Strengths from './Strengths.jsx'
import {
    Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography, Paper, Container, Avatar, Stack,
    Card, Autocomplete, TextField, Fade, CardContent, Tooltip,
    Chip, FormGroup, FormControlLabel

} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Goals from './Goals.jsx';
import api from '../apis/api.js';
import BackdropLoader from './common/BackdropLoader.jsx'
import styles from '../css/List-users.module.css';
import InputSearch from './InputSearch.jsx'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CheckIn from './CheckIns.jsx';
import RequiredCheckIn from './RequiredCheckIn.jsx';
import { EmployeesContext } from '../context/UsersContext.js';
import { red, green, yellow } from '@mui/material/colors';
import FirstGoalPDF from './FirstGoal.jsx';

const getStatusPeopleBuilder = (goals) => {
    let status = 'No Goals'
    const statusGoals = new Set()
    goals.map(goal => statusGoals.add(goal?.goal_status))

    if (statusGoals.has('Draft Goal')) {
        status = 'Draft Goal'
    } else if (statusGoals.has('Approval')) {
        status = 'Approval'
    } else if (statusGoals.has('Needs Revision')) {
        status = 'Needs Revision'
    } else if (statusGoals.has('Active')) {
        status = 'Active'
    } else if (statusGoals.has('Completed')) {
        status = 'Completed'
    } else {
        status = 'No Goals'
    }

    return status
}

const PeopleBuilderRow = React.forwardRef((props, ref) => {
    const { playerId, goals, peopleBuilder, getGoals, pbStatus, setPbStatus, setTitle, title, checkIns, handleScroll } = props;
    const [open, setOpen] = React.useState(false);
    const [colorChip, setColorChip] = useState('default')
    const [colorTextChip, setColorTextChip] = useState('default')
    const params = useParams()
    const [pbData, setPbData] = useState({
        year: null,
        status: null,
        qty_goals: null,
        creating: null,
        last_checkin: null
    })

    const filterGoalsByUser = goals.filter(goal => goal?.people_builder?.player_id === playerId && goal?.people_builder?.year === peopleBuilder?.year)
    const status = getStatusPeopleBuilder(filterGoalsByUser)

    const updateColorChip = useCallback(() => {
        if (status == 'Draft Goal' || status == 'Approval') {
            setColorChip(yellow[300])
            setColorTextChip('black')
        } else if (status == 'No Goals') {
            setColorChip(red[300])
            setColorTextChip('black')
        } else if (status == 'Needs Revision') {
            setColorChip(red[300])
            setColorTextChip('black')
        } else if (status == 'Active' || status == 'Completed') {
            setColorChip(green[200])
            setColorTextChip('black')
        } else {
            setColorChip('')
            setColorTextChip('')
        }

    })

    const updateStatus = useCallback(() => {
        setPbStatus(status)
    }, [status])

    const findMostRecentCheckin = (checkins) => {
        if (checkins?.length === 0) return null;
        
        const checinsFilteredByYear = checkins?.filter(checkin => new Date(checkin.check_in_date).getFullYear() === peopleBuilder?.year)
        
        if (checinsFilteredByYear?.length === 0) return null;

        return checinsFilteredByYear?.reduce((mostRecent, current) => {
            return new Date(current.check_in_date) > new Date(mostRecent.check_in_date) ? current : mostRecent;
        });
    };

    const buildData = useCallback(() => {

        const userCheckins = checkIns?.filter(checkin => checkin.player_id === playerId)
        const mostRecentCheckin = findMostRecentCheckin(userCheckins);

        setPbData(prevStates => ({
            ...prevStates,
            qty_goals: filterGoalsByUser.length,
            status: filterGoalsByUser.length > 0 ? pbStatus : 'No Goals',
            creating: peopleBuilder?.created_at.split('T')[0],
            year: peopleBuilder?.year,
            last_checkin: mostRecentCheckin?.check_in_date.split('T')[0]
        }))
    }, [filterGoalsByUser, peopleBuilder, checkIns])

    useEffect(() => {
        buildData()
        updateStatus()
        updateColorChip()
    }, [goals, pbStatus, checkIns])

    useEffect(() => {
        setOpen(localStorage.getItem('playerIdClickedPB') === peopleBuilder?.player.id + peopleBuilder?.year)
    }, [open])

    return (
        <React.Fragment >
            <TableRow
                hover
                ref={ref}
                sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
                onClick={(e) => {
                    localStorage.setItem('playerIdClickedPB', peopleBuilder?.player.id + peopleBuilder?.year);
                    handleScroll()
                    setOpen(!open)
                }}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Stack direction='row' alignItems='center' gap={1}>
                        <Avatar src={peopleBuilder?.player.photo_url} />
                        <Typography variant='body1'>{peopleBuilder?.player.full_name}</Typography>
                    </Stack>
                </TableCell>
                <TableCell>
                    <Stack direction='row' alignItems='center' gap={1}>
                        <Avatar src={peopleBuilder?.coach?.photo_url ?? ''} />
                        <Typography variant='body1'>{peopleBuilder?.coach?.full_name ?? ''}</Typography>
                    </Stack>
                </TableCell>
                <TableCell>{pbData.year}</TableCell>
                <TableCell>
                    <Chip sx={{ backgroundColor: colorChip, color: colorTextChip }} label={status} />
                </TableCell>
                <TableCell>{pbData.qty_goals}</TableCell>
                <TableCell>{pbData.creating}</TableCell>
                <TableCell>{pbData.last_checkin}</TableCell>
                {/* <TableCell>
					{isCoach || isPlayer ? (
						<Tooltip title='New Goal'>
							<IconButton onClick={openGoalForm}>
								<AddCircleOutlineIcon />
							</IconButton>
						</Tooltip>
					) : (
						<Tooltip title={`You do not have access to create new Goal`}>
							<Box>
								<IconButton disabled onClick={openGoalForm}>
									<AddCircleOutlineIcon />
								</IconButton>
							</Box>
						</Tooltip>
					)}
				</TableCell> */}
            </TableRow>
            <TableRow hover >
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={8}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Container maxWidth='sm' sx={{ mt: 2 }}>
                            <Strengths employeeId={playerId} styles={{ marginRight: 10 }} />
                        </Container>
                        <Paper sx={{ m: 2, p: 2 }}>
                            <Typography
                                variant="h6"
                                textAlign='center'
                                gutterBottom
                                component="div"
                            >
                                Goals
                            </Typography>
                            <Goals peopleBuilder={peopleBuilder} playerId={playerId} getGoals={getGoals} goals={goals} />
                            {/* <Stack sx={{ mt: 2, justifyContent: 'right' }} direction='row' alignItems='center' gap={1}>
                                <Avatar src={peopleBuilder?.coach?.photo_url ?? ''} />
                                <Typography variant='body1'>{peopleBuilder?.coach?.full_name ?? ''}</Typography>
                            </Stack> */}
                            {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Tooltip title="Add Goal">
                                    <IconButton >
                                        <AddCircleOutlineIcon sx={{ cursor: 'pointer' }} />
                                    </IconButton>
                                </Tooltip>
                            </Box> */}
                        </Paper>
                        <CheckIn peopleBuilder={peopleBuilder} getGoals={getGoals} goals={goals} playerId={playerId} />
                        <RequiredCheckIn setTitle={setTitle} title={title} peopleBuilder={peopleBuilder} playerId={playerId} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
})

export default PeopleBuilderRow